import * as ActionTypes from '../constants/actions4questions';

export function questionsLoad() {
  return { type: ActionTypes.QUESTIONS_LOAD, payload: {} };
}
export function questionsLoadSuccess(questions) {
  return { type: ActionTypes.QUESTIONS_LOAD_SUCCESS, payload: { questions } };
}
export function questionsLoadFailure(errorMessage) {
  return { type: ActionTypes.QUESTIONS_LOAD_FAILURE, payload: { error: { message: errorMessage } } };
}

export function questionsEditorLoadBySlug(questionslug) {
  return { type: ActionTypes.UI_QUESTIONSEDITOR_LOAD_BY_SLUG, payload: { questionslug } };
}

export function questionsEditorBeforeSelectQuestion(question) {
  return { type: ActionTypes.UI_QUESTIONSEDITOR_BEFORE_SELECT_QUESTION, payload: { question } };
}

export function questionsEditorAfterSelectQuestion(question) {
  return { type: ActionTypes.UI_QUESTIONSEDITOR_AFTER_SELECT_QUESTION, payload: { question } };
}

export function questionsEditorSetFormDirty() {
  return { type: ActionTypes.UI_QUESTIONSEDITOR_SET_FORM_DIRTY, payload: { } };
}

export function questionsCrudUpdate(question, removedFrom, addedTo) {
  return { type: ActionTypes.QUESTIONS_CRUD_UPDATE, payload: { question, removedFrom, addedTo } };
}
export function questionsCrudUpdateSuccess(question, removedFrom, addedTo) {
  return { type: ActionTypes.QUESTIONS_CRUD_UPDATE_SUCCESS, payload: { question, removedFrom, addedTo } };
}
export function questionsCrudUpdateFailure(errorMessage) {
  return { type: ActionTypes.QUESTIONS_CRUD_UPDATE_FAILURE, payload: { error: { message: errorMessage } } };
}

export function questionsCrudCreate(question, addedTo) {
  return { type: ActionTypes.QUESTIONS_CRUD_CREATE, payload: { question, addedTo } };
}
export function questionsCrudCreateSuccess(question, addedTo) {
  return { type: ActionTypes.QUESTIONS_CRUD_CREATE_SUCCESS, payload: { question, addedTo } };
}
export function questionsCrudCreateFailure(errorMessage) {
  return { type: ActionTypes.QUESTIONS_CRUD_CREATE_FAILURE, payload: { error: { message: errorMessage } } };
}

export function questionsCrudDelete(questionUuid) {
  return { type: ActionTypes.QUESTIONS_CRUD_DELETE, payload: { questionUuid } };
}
export function questionsCrudDeleteSuccess(questionUuid) {
  return { type: ActionTypes.QUESTIONS_CRUD_DELETE_SUCCESS, payload: { questionUuid } };
}
export function questionsCrudDeleteFailure(errorMessage) {
  return { type: ActionTypes.QUESTIONS_CRUD_DELETE_FAILURE, payload: { error: { message: errorMessage } } };
}
