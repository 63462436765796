import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Alert, Breadcrumb, Button, Icon } from 'antd';
import { Layout } from 'antd';

import { challengesLoad, bannersLoad, bannersEditorBeforeSelectBanner, bannersEditorLoadById,  } from '../../actions';

import BannerSelector from '../BannerSelector';
import BannerForm from '../BannerForm';

import '../../styles/editor.css';

const {
	Content, Sider
} = Layout;

/* eslint react/forbid-prop-types: 0 */

class BannersEditorComponent extends React.Component {
	componentDidMount() {
		if (!this.props.banners) {
			this.props.bannersLoad();
		}
		if (!this.props.challenges) {
			this.props.challengesLoad();
		}
		if (this.props.match.params.bannerid) {
			this.props.bannersEditorLoadById(this.props.match.params.bannerid);
		}
	}

	onItemChange = (banner) => {
		this.props.bannersEditorBeforeSelectBanner(banner);
	}

	onCreate = () => {
		this.props.bannersEditorBeforeSelectBanner({
			label: 'my banner',
			target: '',
			type: 'desktop728x90',
		});
	}

	render() {
	if (!this.props.loadError && (!this.props.banners || !this.props.challenges))
		return <Icon type="loading" />;

	return (
		<div>
			<Breadcrumb>
				<Breadcrumb.Item>
				<Link to="/">Home</Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
				Banners Editor
				</Breadcrumb.Item>
			</Breadcrumb>
			<div className="content">
				{this.props.loadError ?
				(<Alert type="error" message={this.props.loadError.message} />)
				: (
					<Layout>
						<Sider theme="light" width={350}>
							<BannerSelector
								banners={this.props.banners}
								banner={this.props.banner ? this.props.banner.uuid : null}
								onItemChange={this.onItemChange}
							/>
						</Sider>
						<Content>
							<Button onClick={this.onCreate} type="primary" className="hor_btn">Create New Banner</Button>
							<div className="editor_form">
								{ this.props.banner  ? (
									<BannerForm key={this.props.banner.uuid} />
								) : (
									<div>None selected</div>
								)}
							</div>
						</Content>
					</Layout>
				)
				}
			</div>
		</div>
	);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		challenges: state.challenges.data,
		banners: state.banners.data,
		loadError: state.errors.loadError,
		banner: state.bannersEditor.banner,
		params: ownProps.match.params,
	};
}

export default connect(mapStateToProps, {
											challengesLoad,
											bannersLoad,
											bannersEditorLoadById,
											bannersEditorBeforeSelectBanner,
										}
				)(BannersEditorComponent);
