import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'connected-react-router';

import userManager from '../../utils/userManager';

class CallbackPageComponent extends React.Component {
  successCallback = () => {
    this.props.dispatch(push('/dashboard'));
  }
  errorCallback = () => {
    this.props.dispatch(push('/'));
  }

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div>
          Redirecting...
        </div>
      </CallbackComponent>
    );
  }
}

export { CallbackPageComponent };
export default connect()(CallbackPageComponent);
