import * as ActionTypes from '../constants/actions4tags';

export function tagsLoad() {
  return { type: ActionTypes.TAGS_LOAD, payload: {} };
}
export function tagsLoadSuccess(tags) {
  return { type: ActionTypes.TAGS_LOAD_SUCCESS, payload: { tags } };
}
export function tagsLoadFailure(errorMessage) {
  return { type: ActionTypes.TAGS_LOAD_FAILURE, payload: { error: { message: errorMessage } } };
}

export function tagsEditorLoadBySlug(tagslug) {
  return { type: ActionTypes.UI_TAGSEDITOR_LOAD_BY_SLUG, payload: { tagslug } };
}

export function tagsEditorBeforeSelectTag(tag) {
  return { type: ActionTypes.UI_TAGSEDITOR_BEFORE_SELECT_TAG, payload: { tag } };
}

export function tagsEditorAfterSelectTag(tag) {
  return { type: ActionTypes.UI_TAGSEDITOR_AFTER_SELECT_TAG, payload: { tag } };
}

export function tagsEditorSetFormDirty() {
  return { type: ActionTypes.UI_TAGSEDITOR_SET_FORM_DIRTY, payload: { } };
}

export function tagsCrudUpdate(tag) {
  return { type: ActionTypes.TAGS_CRUD_UPDATE, payload: { tag } };
}
export function tagsCrudUpdateSuccess(tag) {
  return { type: ActionTypes.TAGS_CRUD_UPDATE_SUCCESS, payload: { tag } };
}
export function tagsCrudUpdateFailure(errorMessage) {
  return { type: ActionTypes.TAGS_CRUD_UPDATE_FAILURE, payload: { error: { message: errorMessage } } };
}

export function tagsCrudCreate(tag) {
  return { type: ActionTypes.TAGS_CRUD_CREATE, payload: { tag } };
}
export function tagsCrudCreateSuccess(tag) {
  return { type: ActionTypes.TAGS_CRUD_CREATE_SUCCESS, payload: { tag } };
}
export function tagsCrudCreateFailure(errorMessage) {
  return { type: ActionTypes.TAGS_CRUD_CREATE_FAILURE, payload: { error: { message: errorMessage } } };
}

export function tagsCrudDelete(tagUuid) {
  return { type: ActionTypes.TAGS_CRUD_DELETE, payload: { tagUuid } };
}
export function tagsCrudDeleteSuccess(tagUuid) {
  return { type: ActionTypes.TAGS_CRUD_DELETE_SUCCESS, payload: { tagUuid } };
}
export function tagsCrudDeleteFailure(errorMessage) {
  return { type: ActionTypes.TAGS_CRUD_DELETE_FAILURE, payload: { error: { message: errorMessage } } };
}
