import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Alert, Breadcrumb, Button, Icon } from 'antd';
import { Layout } from 'antd';

import { challengesLoad, questionsLoad, questionsEditorBeforeSelectQuestion, questionsEditorLoadBySlug, tagsLoad } from '../../actions';

import { SAMPLE_SINGLE_CHOICE } from '../../constants/sampleQuestions';

import ChallengeTreeQuestionSelector from '../ChallengeTreeQuestionSelector';
import QuestionForm from '../QuestionForm';

import './style.css';

const {
	Content, Sider
} = Layout;

/* eslint react/forbid-prop-types: 0 */

class QuestionsEditorComponent extends React.Component {
	componentDidMount() {
		if (!this.props.questions) {
			this.props.questionsLoad();
		}
		if (!this.props.tags) {
			this.props.tagsLoad();
		}
		if (!this.props.challenges) {
			this.props.challengesLoad();
		}
		if (this.props.match.params.questionslug) {
			this.props.questionsEditorLoadBySlug(this.props.match.params.questionslug);
		}
	}

	onItemChange = (question) => {
		this.props.questionsEditorBeforeSelectQuestion(question);
	}

	onCreate = () => {
		this.props.questionsEditorBeforeSelectQuestion({
			slug: 'new-slug',
			text: SAMPLE_SINGLE_CHOICE,
			type: 'SINGLE_CHOICE',
			tags: [],
		});
	}

	render() {
	if (!this.props.loadError && (!this.props.tags || !this.props.questions || !this.props.challenges))
		return <Icon type="loading" />;

	return (
		<div>
			<Breadcrumb>
				<Breadcrumb.Item>
				<Link to="/">Home</Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
				Questions Editor
				</Breadcrumb.Item>
			</Breadcrumb>
			<div className="content">
				{this.props.loadError ?
				(<Alert type="error" message={this.props.loadError.message} />)
				: (
					<Layout>
						<Sider theme="light" width={350}>
							<ChallengeTreeQuestionSelector
								challenges={this.props.challenges}
								questions={this.props.questions}
								question={this.props.question ? this.props.question.slug : null}
								onItemChange={this.onItemChange}
							/>
						</Sider>
						<Content>
							<Button onClick={this.onCreate} type="primary" className="hor_btn">Create New Question</Button>
							<div className="question_form">
								{ this.props.question  ? (
									<QuestionForm key={this.props.question.slug} />
								) : (
									<div>None selected</div>
								)}
							</div>
						</Content>
					</Layout>
				)
				}
			</div>
		</div>
	);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		challenges: state.challenges.data,
		tags: state.tags.data,
		questions: state.questions.data,
		loadError: state.errors.loadError,
		question: state.questionsEditor.question,
		params: ownProps.match.params,
	};
}

export default connect(mapStateToProps, {
											challengesLoad,
											questionsLoad,
											tagsLoad,
											questionsEditorLoadBySlug,
											questionsEditorBeforeSelectQuestion,
										}
				)(QuestionsEditorComponent);

/*
{
	questions {
	slug
	type
	task
	options {
		index
		isCorrect
		value
		id
		uuid
		comment
	}
	}
}

example

{
		"slug": "reported-speech-yesterday-tomorrow",
		"type": "MULTI_CHOICE",
		"task": "Change direct speech to reported in the following sentence.\n\nToday, John told me: \"I bought a lottery ticket yesterday and I will collect my prize tomorrow.\"\n\n",
		"options": [
			{
			"index": 1,
			"isCorrect": true,
			"value": "Today, John told me that he bought a lottery ticket yesterday and he would collect his prize tomorrow.",
			"id": 76,
			"uuid": "7027deb3-9a05-45f9-aee3-ff9c6e4f0ce7",
			"comment": "\"Yesterday\" and \"tomorrow\" are still correct at the moment of speaking, so they do not change in indirect speech.\n\n"
			},
			{
			"index": 2,
			"isCorrect": false,
			"value": "Today, John told me that he had bought a lottery ticket yesterday and he would collect his prize the next day.",
			"id": 77,
			"uuid": "5756739e-5b98-4cff-a0c5-05ee91cc1f48",
			"comment": "\"Tomorrow\" is still \"tomorrow\" at the moment of speaking so it does not change.\n\n"
			},
			{
			"index": 3,
			"isCorrect": false,
			"value": "Today John told me that he bought a lottery ticket yesterday and he would collect his prize the following day.",
			"id": 89,
			"uuid": "c3937ac6-62ac-4f1a-872f-816db82b1ae2",
			"comment": "\"Tomorrow\" is still \"tomorrow\" at the moment of speaking, so it stays the same in the indirect speech sentence.\n\n"
			},
			{
			"index": 4,
			"isCorrect": false,
			"value": "Today, John told me that he had bought a lottery ticket the day before and he would collect his prize the following day.",
			"id": 125,
			"uuid": "8f1dc703-cbe0-4ec3-9610-7cfb1ed1be3c",
			"comment": "\"Yesterday\" is still \"yesterday\" at the moment of speaking, so the past tense and the \"yesterday\" stays the same in the indirect sentence. \"Tomorrow\" is also still \"tomorrow\" at the moment of speaking so it does not change.\n\n"
			}
		]
		},

 */
