import React from 'react';
import { Tree } from 'antd';

const { TreeNode } = Tree;

function ChallengeTreeQuestionSelectorComponent(props) {

  const selectChallenge = !!props.selectChallenge;

	const getQuestionBySlug = value => props.questions.filter(q => q.slug === value)[0];
  const getChallengeBySlug = value => props.challenges.filter(c => c.slug === value)[0];

	const onSelect = (selectedKeys, info) => {
		if (props.onItemChange) props.onItemChange(selectChallenge
                                                ? getChallengeBySlug(selectedKeys[0])
                                                : getQuestionBySlug(selectedKeys[0])
                                              );
  	};

  	const buildLeafs = (questions) => {
  		return questions.map(question => {
  			return <TreeNode
                 selectable={!selectChallenge}
                 isLeaf={true}
                 title={question.slug}
                 key={question.slug}
               />
  		});
  	}

  	const buildTree = (challenges, questions) => {
  		return selectChallenge ? challenges.map(challenge => {
        return (
          <TreeNode
            selectable={selectChallenge}
            title={challenge.title}
            key={challenge.slug}
          >
            {buildLeafs(challenge.questions)}
          </TreeNode>
        );
      }) : [...challenges.map(challenge => {
  			return (
  				<TreeNode
  					selectable={selectChallenge}
  					title={challenge.title}
  					key={challenge.slug}
  				>
  					{buildLeafs(challenge.questions)}
  				</TreeNode>
  			);
  		}), (
          <TreeNode
            selectable={selectChallenge}
            title={'.Unassigned'}
            key={'__unassigned'}
          >
            {buildLeafs(questions.filter(q => !challenges.some(c => c.questions.some(q1 => q1.uuid == q.uuid))))}
          </TreeNode>
        )];
  	}

	const questions = props.questions;
	const challenges = props.challenges;

	const nodes = !questions && !challenges
                  ? null
									: buildTree(challenges, questions)
	return (
		<Tree
			onSelect={onSelect}
			selectedKeys={[props.question || props.challenge || undefined]}
    >
			{nodes}
		</Tree>
	);
}

export default ChallengeTreeQuestionSelectorComponent;
