import * as ACTIONS from '../constants/actions';

const initialState = {
}

function challenges(state = initialState, action) {
	switch (action.type) {
		case ACTIONS.CHALLENGES_LOAD_SUCCESS: {
			return { ...state,
				data: action.payload.challenges,
			};
		}
		case ACTIONS.CHALLENGES_CRUD_UPDATE_SUCCESS: {
			return { ...state,
				data: state.data.map(t => { return t.slug === action.payload.challengeSlug ? {
					...action.payload.challenge,
				} : t}),
			};
		}
		case ACTIONS.CHALLENGES_CRUD_CREATE_SUCCESS: {
			let newdata = [...state.data];
			newdata.push({
				...action.payload.challenge,
			});
			return { ...state,
				data: newdata,
			};
		}
		case ACTIONS.CHALLENGES_CRUD_DELETE_SUCCESS: {
			return { ...state,
				data: state.data.filter(t => t.uuid !== action.payload.challengeUuid),
			};
		}
		case ACTIONS.QUESTIONS_CRUD_UPDATE_SUCCESS: {
			const { slug, uuid } = action.payload.question;
			return { ...state,
				data: state.data.map(t => {
					if (action.payload.addedTo.some(c => c.uuid == t.uuid)){
						return {...t, questions: [...t.questions, { uuid, slug}]};
					} else if (action.payload.removedFrom.some(c => c.uuid == t.uuid)){
						return {...t, questions: t.questions.filter(q => q.uuid != uuid)};
					} else {
						return t;
					}
				}),
			};
		}
		case ACTIONS.QUESTIONS_CRUD_CREATE_SUCCESS: {
			const { slug, uuid } = action.payload.question;
			return { ...state,
				data: state.data.map(t => {
					if (action.payload.addedTo.some(c => c.uuid == t.uuid)){
						return {...t, questions: [...t.questions, { uuid, slug}]};
					} else {
						return t;
					}
				}),
			};
		}
		case ACTIONS.QUESTIONS_CRUD_DELETE_SUCCESS: {
			return { ...state,
				data: state.data.map(t => {
					if (t.questions.some(c => c.uuid == action.payload.questionUuid)){
						return {...t, questions: t.questions.filter(c => c.uuid != action.payload.questionUuid)};
					} else {
						return t;
					}
				}),
			};
		}
		case ACTIONS.TAGS_CRUD_DELETE_SUCCESS: {
			return { ...state,
				data: state.data.map(t => {
					if (t.tags.some(c => c.uuid == action.payload.tagUuid)){
						return {...t, tags: t.tags.filter(c => c.uuid != action.payload.tagUuid)};
					} else {
						return t;
					}
				}),
			};
		}
		default: {
			return state;
		}
	}
}

export default challenges;
