import * as ActionTypes from '../constants/actions4challenges';

export function challengesLoad() {
  return { type: ActionTypes.CHALLENGES_LOAD, payload: {} };
}
export function challengesLoadSuccess(challenges) {
  return { type: ActionTypes.CHALLENGES_LOAD_SUCCESS, payload: { challenges } };
}
export function challengesLoadFailure(errorMessage) {
  return { type: ActionTypes.CHALLENGES_LOAD_FAILURE, payload: { error: { message: errorMessage } } };
}

export function challengesEditorLoadBySlug(challengeslug) {
  return { type: ActionTypes.UI_CHALLENGESEDITOR_LOAD_BY_SLUG, payload: { challengeslug } };
}

export function challengesEditorBeforeSelectChallenge(challenge) {
  return { type: ActionTypes.UI_CHALLENGESEDITOR_BEFORE_SELECT_CHALLENGE, payload: { challenge } };
}

export function challengesEditorAfterSelectChallenge(challenge) {
  return { type: ActionTypes.UI_CHALLENGESEDITOR_AFTER_SELECT_CHALLENGE, payload: { challenge } };
}

export function challengesEditorSetFormDirty() {
  return { type: ActionTypes.UI_CHALLENGESEDITOR_SET_FORM_DIRTY, payload: { } };
}

export function challengesCrudUpdate(challenge) {
  return { type: ActionTypes.CHALLENGES_CRUD_UPDATE, payload: { challenge } };
}
export function challengesCrudUpdateSuccess(challenge) {
  return { type: ActionTypes.CHALLENGES_CRUD_UPDATE_SUCCESS, payload: { challenge } };
}
export function challengesCrudUpdateFailure(errorMessage) {
  return { type: ActionTypes.CHALLENGES_CRUD_UPDATE_FAILURE, payload: { error: { message: errorMessage } } };
}

export function challengesCrudCreate(challenge) {
  return { type: ActionTypes.CHALLENGES_CRUD_CREATE, payload: { challenge } };
}
export function challengesCrudCreateSuccess(challenge) {
  return { type: ActionTypes.CHALLENGES_CRUD_CREATE_SUCCESS, payload: { challenge } };
}
export function challengesCrudCreateFailure(errorMessage) {
  return { type: ActionTypes.CHALLENGES_CRUD_CREATE_FAILURE, payload: { error: { message: errorMessage } } };
}

export function challengesCrudDelete(challengeUuid) {
  return { type: ActionTypes.CHALLENGES_CRUD_DELETE, payload: { challengeUuid } };
}
export function challengesCrudDeleteSuccess(challengeUuid) {
  return { type: ActionTypes.CHALLENGES_CRUD_DELETE_SUCCESS, payload: { challengeUuid } };
}
export function challengesCrudDeleteFailure(errorMessage) {
  return { type: ActionTypes.CHALLENGES_CRUD_DELETE_FAILURE, payload: { error: { message: errorMessage } } };
}

