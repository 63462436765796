import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Alert, Breadcrumb, Button, Icon } from 'antd';
import { Layout } from 'antd';

import { challengesLoad, questionsLoad, tagsLoad, bannersLoad, challengesEditorBeforeSelectChallenge, challengesEditorLoadBySlug,  } from '../../actions';

import ChallengeTreeQuestionSelector from '../ChallengeTreeQuestionSelector';
import ChallengeForm from '../ChallengeForm';

import '../../styles/editor.css';

const {
	Content, Sider
} = Layout;

/* eslint react/forbid-prop-types: 0 */

class ChallengesEditorComponent extends React.Component {
	componentDidMount() {
		if (!this.props.questions) {
			this.props.questionsLoad();
		}
		if (!this.props.tags) {
			this.props.tagsLoad();
		}
		if (!this.props.banners) {
			this.props.bannersLoad();
		}
		if (!this.props.challenges) {
			this.props.challengesLoad();
		}
		if (this.props.match.params.challengeslug) {
			this.props.challengesEditorLoadBySlug(this.props.match.params.challengeslug);
		}
	}

	onItemChange = (challenge) => {
		this.props.challengesEditorBeforeSelectChallenge(challenge);
	}

	onCreate = () => {
		this.props.challengesEditorBeforeSelectChallenge({
			slug: 'new-challenge-slug',
			shortDescription: '',
			description: '',
			title: 'New challenge',
			tags: [],
			questions: [],
			banners: [],
		});
	}

	render() {
	if (!this.props.loadError && (!this.props.tags || !this.props.questions || !this.props.banners || !this.props.challenges))
		return <Icon type="loading" />;

	return (
		<div>
			<Breadcrumb>
				<Breadcrumb.Item>
				<Link to="/">Home</Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
				Challenges Editor
				</Breadcrumb.Item>
			</Breadcrumb>
			<div className="content">
				{this.props.loadError ?
				(<Alert type="error" message={this.props.loadError.message} />)
				: (
					<Layout>
						<Sider theme="light" width={350}>
							<ChallengeTreeQuestionSelector
								selectChallenge={true}
								challenges={this.props.challenges}
								questions={this.props.questions}
								challenge={this.props.challenge ? this.props.challenge.slug : null}
								onItemChange={this.onItemChange}
							/>
						</Sider>
						<Content>
							<Button onClick={this.onCreate} type="primary" className="hor_btn">Create New Challenge</Button>
							<div className="editor_form">
								{ this.props.challenge  ? (
									<ChallengeForm key={this.props.challenge.slug} />
								) : (
									<div>None selected</div>
								)}
							</div>
						</Content>
					</Layout>
				)
				}
			</div>
		</div>
	);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		challenges: state.challenges.data,
		tags: state.tags.data,
		questions: state.questions.data,
		banners: state.banners.data,
		loadError: state.errors.loadError,
		challenge: state.challengesEditor.challenge,
		params: ownProps.match.params,
	};
}

export default connect(mapStateToProps, {
											challengesLoad,
											questionsLoad,
											tagsLoad,
											bannersLoad,
											challengesEditorLoadBySlug,
											challengesEditorBeforeSelectChallenge,
										}
				)(ChallengesEditorComponent);
