import * as ACTIONS from '../constants/actions';

const initialState = {
	banner: null,
	dirty: false,
  	loaded: {},
}

function bannersEditor(state = initialState, action) {
	switch (action.type) {
		case ACTIONS.UI_BANNERSEDITOR_AFTER_SELECT_BANNER: {
			return { ...state,
				banner: action.payload.banner,
				dirty: false,
			};
		}
		case ACTIONS.UI_BANNERSEDITOR_SET_FORM_DIRTY: {
			return { ...state,
				dirty: true,
			};
		}
	    case ACTIONS.BANNERS_CRUD_CREATE_SUCCESS: {
	      return { ...state,
	        banner: action.payload.banner,
	        loaded: {...state.loaded, [action.payload.banner.uuid]:action.payload.banner },
	        dirty: false,
	      };
	    }
	    case ACTIONS.BANNERS_CRUD_UPDATE_SUCCESS: {
	      let loaded = {...state.loaded};
	      Object.keys(loaded).forEach(k => {
	        if (k === action.payload.banner.uuid) {
	          loaded[k] = action.payload.banner;
	        }
	      });
	      return { ...state,
	        banner: action.payload.banner,
	        loaded,
	        dirty: false,
	      };
	    }
	    case ACTIONS.BANNERS_CRUD_DELETE_SUCCESS: {
	      let loaded = {...state.loaded};
	      Object.keys(loaded).forEach(k => {
	        if (k === action.payload.bannerUuid) {
	          delete loaded[k];
	        }
	      });
	      return { ...state,
	        banner: null,
	        loaded,
	        dirty: false,
	      };
	    }
		default: {
			return state;
		}
	}
}

export default bannersEditor;
