import React from 'react';
import { Tree } from 'antd';

const { TreeNode } = Tree;

function BannerSelectorComponent(props) {

	const getBannerById = value => props.banners.filter(b => b.uuid === value)[0];

	const onSelect = (selectedKeys, info) => {
  		if (props.onItemChange) props.onItemChange(getBannerById(selectedKeys[0]));
  }

	const buildTree = (banners) => {
		return banners.map(banner => {
			return (
				<TreeNode
					selectable={true}
					title={banner.label}
					key={banner.uuid}
				>
				</TreeNode>
			);
		});
	}

	const banners = props.banners;

	const nodes = banners ? buildTree(banners) : null;
	return (
		<Tree
			onSelect={onSelect}
			selectedKeys={[props.banner || undefined]}
    >
			{nodes}
		</Tree>
	);
}

export default BannerSelectorComponent;
