export const QUESTIONS_LOAD = 'QUESTIONS_LOAD';
export const QUESTIONS_LOAD_SUCCESS = 'QUESTIONS_LOAD_SUCCESS';
export const QUESTIONS_LOAD_FAILURE = 'QUESTIONS_LOAD_FAILURE';

export const UI_QUESTIONSEDITOR_BEFORE_SELECT_QUESTION = 'UI_QUESTIONSEDITOR_BEFORE_SELECT_QUESTION';
export const UI_QUESTIONSEDITOR_AFTER_SELECT_QUESTION = 'UI_QUESTIONSEDITOR_AFTER_SELECT_QUESTION';

export const UI_QUESTIONSEDITOR_SET_FORM_DIRTY = 'UI_QUESTIONSEDITOR_SET_FORM_DIRTY';

export const QUESTIONS_CRUD_UPDATE = 'QUESTIONS_CRUD_UPDATE';
export const QUESTIONS_CRUD_UPDATE_SUCCESS = 'QUESTIONS_CRUD_UPDATE_SUCCESS';
export const QUESTIONS_CRUD_UPDATE_FAILURE = 'QUESTIONS_CRUD_UPDATE_FAILURE';

export const QUESTIONS_CRUD_CREATE = 'QUESTIONS_CRUD_CREATE';
export const QUESTIONS_CRUD_CREATE_SUCCESS = 'QUESTIONS_CRUD_CREATE_SUCCESS';
export const QUESTIONS_CRUD_CREATE_FAILURE = 'QUESTIONS_CRUD_CREATE_FAILURE';

export const QUESTIONS_CRUD_DELETE = 'QUESTIONS_CRUD_DELETE';
export const QUESTIONS_CRUD_DELETE_SUCCESS = 'QUESTIONS_CRUD_DELETE_SUCCESS';
export const QUESTIONS_CRUD_DELETE_FAILURE = 'QUESTIONS_CRUD_DELETE_FAILURE';

export const UI_QUESTIONSEDITOR_LOAD_BY_SLUG = 'UI_QUESTIONSEDITOR_LOAD_BY_SLUG';
