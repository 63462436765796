import * as ACTIONS from '../constants/actions';

const initialState = {
	loadError: null,
	tagsLoadError: null,
	challengesLoadError: null,
	bannersLoadError: null,
	questionsLoadError: null,
	saveError: null,
	tagsSaveError: null,
	questionsSaveError: null,
	challengesSaveError: null,
	bannersSaveError: null,
}

function errors(state = initialState, action) {
	switch (action.type) {
		case ACTIONS.BANNERS_LOAD_SUCCESS: {
			return { ...state,
				bannersLoadError: null,
				loadError: state.bannersLoadError || state.challengesLoadError || state.tagsLoadError || state.questionsLoadError,
			};
		}
		case ACTIONS.BANNERS_LOAD_FAILURE: {
			return { ...state,
				bannersLoadError: action.payload.error,
				loadError: action.payload.error,
			};
		}
		case ACTIONS.CHALLENGES_LOAD_SUCCESS: {
			return { ...state,
				challengesLoadError: null,
				loadError: state.bannersLoadError || state.challengesLoadError || state.tagsLoadError || state.questionsLoadError,
			};
		}
		case ACTIONS.CHALLENGES_LOAD_FAILURE: {
			return { ...state,
				challengesLoadError: action.payload.error,
				loadError: action.payload.error,
			};
		}
		case ACTIONS.QUESTIONS_LOAD_SUCCESS: {
			return { ...state,
				questionsLoadError: null,
				loadError: state.bannersLoadError || state.challengesLoadError || state.tagsLoadError || state.questionsLoadError,
			};
		}
		case ACTIONS.QUESTIONS_LOAD_FAILURE: {
			return { ...state,
				questionsLoadError: action.payload.error,
				loadError: action.payload.error,
			};
		}
		case ACTIONS.TAGS_LOAD_SUCCESS: {
			return { ...state,
				tagsLoadError: null,
				loadError: state.bannersLoadError || state.challengesLoadError || state.tagsLoadError || state.questionsLoadError,
			};
		}
		case ACTIONS.TAGS_LOAD_FAILURE: {
			return { ...state,
				tagsLoadError: action.payload.error,
				loadError: action.payload.error,
			};
		}
		case ACTIONS.BANNERS_CRUD_CREATE_SUCCESS:
	    case ACTIONS.BANNERS_CRUD_UPDATE_SUCCESS:
	    case ACTIONS.BANNERS_CRUD_DELETE_SUCCESS:
	    {
	    	return { ...state,
				bannersSaveError: null,
				saveError: null,
			};
		}
	    case ACTIONS.BANNERS_CRUD_CREATE_FAILURE:
	    case ACTIONS.BANNERS_CRUD_UPDATE_FAILURE:
	    case ACTIONS.BANNERS_CRUD_DELETE_FAILURE:
	    {
	      return { ...state,
	      	bannersSaveError: action.payload.error,
	        saveError: action.payload.error,
	      };
	    }
		case ACTIONS.CHALLENGES_CRUD_CREATE_SUCCESS:
	    case ACTIONS.CHALLENGES_CRUD_UPDATE_SUCCESS:
	    case ACTIONS.CHALLENGES_CRUD_DELETE_SUCCESS:
	    {
	    	return { ...state,
				challengesSaveError: null,
				saveError: null,
			};
		}
	    case ACTIONS.CHALLENGES_CRUD_CREATE_FAILURE:
	    case ACTIONS.CHALLENGES_CRUD_UPDATE_FAILURE:
	    case ACTIONS.CHALLENGES_CRUD_DELETE_FAILURE:
	    {
	      return { ...state,
	      	challengesSaveError: action.payload.error,
	        saveError: action.payload.error,
	      };
	    }
		case ACTIONS.TAGS_CRUD_CREATE_SUCCESS:
	    case ACTIONS.TAGS_CRUD_UPDATE_SUCCESS:
	    case ACTIONS.TAGS_CRUD_DELETE_SUCCESS:
	    {
	    	return { ...state,
				tagsSaveError: null,
				saveError: null,
			};
		}
	    case ACTIONS.TAGS_CRUD_CREATE_FAILURE:
	    case ACTIONS.TAGS_CRUD_UPDATE_FAILURE:
	    case ACTIONS.TAGS_CRUD_DELETE_FAILURE:
	    {
	      return { ...state,
	      	tagsSaveError: action.payload.error,
	        saveError: action.payload.error,
	      };
	    }
		case ACTIONS.QUESTIONS_CRUD_CREATE_SUCCESS:
	    case ACTIONS.QUESTIONS_CRUD_UPDATE_SUCCESS:
	    case ACTIONS.QUESTIONS_CRUD_DELETE_SUCCESS:
	    {
	    	return { ...state,
				questionsSaveError: null,
				saveError: null,
			};
		}
	    case ACTIONS.QUESTIONS_CRUD_CREATE_FAILURE:
	    case ACTIONS.QUESTIONS_CRUD_UPDATE_FAILURE:
	    case ACTIONS.QUESTIONS_CRUD_DELETE_FAILURE:
	    {
	      return { ...state,
	      	questionsSaveError: action.payload.error,
	        saveError: action.payload.error,
	      };
	    }
	    default: {
			return state;
		}
	}
}

export default errors;
