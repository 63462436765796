export const TAGS_LOAD = 'TAGS_LOAD';
export const TAGS_LOAD_SUCCESS = 'TAGS_LOAD_SUCCESS';
export const TAGS_LOAD_FAILURE = 'TAGS_LOAD_FAILURE';

export const TAGS_CRUD_UPDATE = 'TAGS_CRUD_UPDATE';
export const TAGS_CRUD_UPDATE_SUCCESS = 'TAGS_CRUD_UPDATE_SUCCESS';
export const TAGS_CRUD_UPDATE_FAILURE = 'TAGS_CRUD_UPDATE_FAILURE';

export const TAGS_CRUD_CREATE = 'TAGS_CRUD_CREATE';
export const TAGS_CRUD_CREATE_SUCCESS = 'TAGS_CRUD_CREATE_SUCCESS';
export const TAGS_CRUD_CREATE_FAILURE = 'TAGS_CRUD_CREATE_FAILURE';

export const TAGS_CRUD_DELETE = 'TAGS_CRUD_DELETE';
export const TAGS_CRUD_DELETE_SUCCESS = 'TAGS_CRUD_DELETE_SUCCESS';
export const TAGS_CRUD_DELETE_FAILURE = 'TAGS_CRUD_DELETE_FAILURE';

export const UI_TAGSEDITOR_LOAD_BY_SLUG = 'UI_TAGSEDITOR_LOAD_BY_SLUG';

export const UI_TAGSEDITOR_BEFORE_SELECT_TAG = 'UI_TAGSEDITOR_BEFORE_SELECT_TAG';
export const UI_TAGSEDITOR_AFTER_SELECT_TAG = 'UI_TAGSEDITOR_AFTER_SELECT_TAG';

export const UI_TAGSEDITOR_SET_FORM_DIRTY = 'UI_TAGSEDITOR_SET_FORM_DIRTY';
