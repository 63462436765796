import * as ActionTypes from '../constants/actions4auth';

export function userLogin() {
  return { type: ActionTypes.USER_LOGIN };
}
export function userLoad() {
  return { type: ActionTypes.USER_LOAD };
}
export function userLogout() {
  return { type: ActionTypes.USER_LOGOUT };
}
