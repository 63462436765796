import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Alert, Breadcrumb, Button, Icon } from 'antd';

import { tagsLoad, tagsEditorBeforeSelectTag, tagsEditorLoadBySlug, questionsLoad } from '../../actions';

import TagSelector from '../TagSelector';
import TagForm from '../TagForm';

import './style.css';

/* eslint react/forbid-prop-types: 0 */

class TagsEditorComponent extends React.Component {
	componentDidMount() {
		if (!this.props.tags) {
			this.props.tagsLoad();
		}
		if (!this.props.questions) {
			this.props.questionsLoad();
		}
		if (this.props.match.params.tagslug) {
			this.props.tagsEditorLoadBySlug(this.props.match.params.tagslug);
		}
	}

	onTagChange = (tag) => {
		this.props.tagsEditorBeforeSelectTag(tag);
	}

	onCreate = () => {
		this.props.tagsEditorBeforeSelectTag({
			slug: 'new-slug',
			parentSlug: null,
			// shortDescription: '',
			// description: '',
			title: 'New Title',
		});
	}

	render() {
		if (!this.props.loadError && (!this.props.tags || !this.props.questions))
			return <Icon type="loading" />;

		return (
			<div>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to="/">Home</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						Tags Editor
					</Breadcrumb.Item>
				</Breadcrumb>
				<div className="content">
					{this.props.loadError ?
						(<Alert type="error" message={this.props.loadError.message} />)
						: (
							<>
								<TagSelector
									onTagChange={this.onTagChange}
									tag={this.props.tag}
									tags={this.props.tags}
								/>
								<Button onClick={this.onCreate} type="primary" className="hor_btn">Create New Tag</Button>
								<div className="tag_form">
										{ this.props.tag  ? (
											<TagForm key={this.props.tag.slug} />
										) : (
											<div>None selected</div>
										)}
								</div>
							</>
						)
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		tags: state.tags.data,
		questions: state.questions.data,
		loadError: state.errors.loadError,
		tag: state.tagsEditor.tag,
		params: ownProps.match.params,
	};
}

export default connect(mapStateToProps, {
	tagsLoad,
	questionsLoad,
	tagsEditorLoadBySlug,
	tagsEditorBeforeSelectTag,
})(TagsEditorComponent);
