const difficultyKey = 'difficulty-level';
const difficultyKeys = ['difficulty-easy', 'difficulty-medium', 'difficulty-hard'];

export function combineTags(tags, created, updated, deleted) {
  let result = tags.filter(tag => !deleted[tag.uuid]);
  result = result.map(tag => updated[tag.uuid] || tag);
  return [...result, ...Object.values(created)];
}

export function chopTrees(flatTrees, valueField = 'slug') {
  let cycle_guard = {};

  const sortByTitle = (a, b) => {
    const au = a.title.toUpperCase();
    const bu = b.title.toUpperCase();
    return au < bu ? -1 : (au === bu ? 0 : 1);
  };

  const sortByDifficulty = (a, b) => {
    const ai = difficultyKeys.indexOf(a.key);
    const bi = difficultyKeys.indexOf(b.key);
    return ai - bi;
  };

  const roots = flatTrees.filter(obj => obj.parentSlug == null).map(obj => ({ title: obj.title, key: obj.uuid, value: obj[valueField], slug: obj.slug }));
  roots.sort(sortByTitle);

  const chopDown = treenode => {
    const children = flatTrees.filter(obj => obj.parentSlug === treenode.slug);
    children.sort(treenode.key === difficultyKey ? sortByDifficulty : sortByTitle);
    treenode.children = children.map(obj => { return {...obj}; }).map(obj => ({ title: obj.title, key: obj.uuid, value: obj[valueField], slug: obj.slug }));
    treenode.children.forEach(child => {
      if (cycle_guard[child.value]) {
        //fail-safe
        child.children = [];
        return; //stop if we're going to cycle
      }
      cycle_guard[child.value] = true;
      chopDown(child);
    })
  };

  roots.forEach((root, index) => {
    chopDown(root);
  })
  return roots;
}
