import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { reducer as oidcReducer } from 'redux-oidc';

import errors from './errors';
import banners from './banners';
import bannersEditor from './bannersEditor';
import challenges from './challenges';
import challengesEditor from './challengesEditor';
import tags from './tags';
import tagsEditor from './tagsEditor';
import questions from './questions';
import questionsEditor from './questionsEditor';

export default (history) => combineReducers({
  router: connectRouter(history),
  oidc: oidcReducer,
  errors,
  banners,
  bannersEditor,
  challenges,
  challengesEditor,
  tags,
  tagsEditor,
  questions,
  questionsEditor,
})
