import * as ACTIONS from '../constants/actions';

const initialState = {
	question: null,
	dirty: false,
  	loaded: {},
}

function questionsEditor(state = initialState, action) {
	switch (action.type) {
		case ACTIONS.UI_QUESTIONSEDITOR_AFTER_SELECT_QUESTION: {
			return { ...state,
				question: action.payload.question,
				dirty: false,
			};
		}
		case ACTIONS.UI_QUESTIONSEDITOR_SET_FORM_DIRTY: {
			return { ...state,
				dirty: true,
			};
		}
	    case ACTIONS.QUESTIONS_CRUD_CREATE_SUCCESS: {
	      return { ...state,
	        question: action.payload.question,
	        loaded: {...state.loaded, [action.payload.question.slug]:action.payload.question },
	        dirty: false,
	      };
	    }
	    case ACTIONS.QUESTIONS_CRUD_UPDATE_SUCCESS: {
	      let loaded = {...state.loaded};
	      Object.keys(loaded).forEach(k => {
	        if (loaded[k].uuid === action.payload.question.uuid) {
	          loaded[k] = action.payload.question;
	        }
	      });
	      return { ...state,
	        question: action.payload.question,
	        loaded,
	        dirty: false,
	      };
	    }
	    case ACTIONS.QUESTIONS_CRUD_DELETE_SUCCESS: {
	      let loaded = {...state.loaded};
	      Object.keys(loaded).forEach(k => {
	        if (loaded[k].uuid === action.payload.questionUuid) {
	          delete loaded[k];
	        }
	      });
	      return { ...state,
	        question: null,
	        loaded,
	        dirty: false,
	      };
	    }
		default: {
			return state;
		}
	}
}

export default questionsEditor;
