import React from 'react';
import { TreeSelect } from 'antd';

import { chopTrees } from '../../utils/tagsHelpers';

class TagSelectorComponent extends React.Component {

  getTagByValue = (value) => {
    return this.props.tags.filter(tag => tag.slug === value)[0];
  }

  getTreeData = () => {
    return this.props.tags ? chopTrees(this.props.tags) : null;
  }

  onTreeChange = (value, node, extra) => {
    if (this.props.onChange) this.props.onChange(value);
    if (this.props.onTagChange) this.props.onTagChange(this.getTagByValue(value));
  }

  render() {
    return (
      <TreeSelect
        allowClear
        style={{ width: 500 }}
        value={this.props.value}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={this.getTreeData()}
        placeholder="Please select tag"
        treeDefaultExpandAll
        onChange={this.onTreeChange}
        showSearch={true}
        multiple={this.props.multiple}
      />
    );
  }
}

export default TagSelectorComponent;
