import React from 'react';
import { Provider, connect } from 'react-redux';
import { Route, Switch } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router'
import { Layout } from 'antd';
import { OidcProvider } from 'redux-oidc';

import { DragDropContextProvider } from 'react-dnd'
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';

import userManager from './utils/userManager';
import store, { history } from './store'

import Home from './components/Home';
import TagsEditor from './components/TagsEditor';
import QuestionsEditor from './components/QuestionsEditor';
import ChallengesEditor from './components/ChallengesEditor';
import BannersEditor from './components/BannersEditor';
import IFrameEditor from './components/IFrameEditor';

import CallbackPage from './components/CallbackPage';
import SilentRenewPage from './components/SilentRenew';
import RequireAuth from './components/RequireAuth';

import './App.css';

const PrivateRouteComponent = RequireAuth(Route);
const PrivateRoute = connect(state => ({ oidc: state.oidc }))(PrivateRouteComponent);

const {
	Header, Footer, Content
} = Layout;

function App() {
	return (
		<Provider store={store}>
		<OidcProvider store={store} userManager={userManager}>
		<DragDropContextProvider backend={MultiBackend(HTML5toTouch)}>
		<ConnectedRouter history={history}>
			<div>
				<Layout>
					<Header className="layoutFrame"><h1 style={{color:'white'}}>Grammar Error Authoring Dashboard</h1></Header>
					<Layout>
						<Content className="content">
							<Switch>
								<Route exact path="/" render={() => (<Home />)} />
								<PrivateRoute path="/tags/:tagslug?" render={routeProps => (<TagsEditor {...routeProps} />)} />
								<PrivateRoute path="/questions/:questionslug?" render={routeProps => (<QuestionsEditor {...routeProps} />)} />
								<PrivateRoute path="/challenges/:challengeslug?" render={routeProps => (<ChallengesEditor {...routeProps} />)} />
								<PrivateRoute path="/banners/:bannerid?" render={routeProps => (<BannersEditor {...routeProps} />)} />
								<PrivateRoute path="/iframe" component={IFrameEditor} />
								<Route path="/auth-callback.html" component={CallbackPage} />
								<Route path="/auth-callback-silent.html" component={SilentRenewPage} />
								<Route render={() => (<Home />)} />
							</Switch>
						</Content>
					</Layout>
					<Footer className="layoutFrame" theme="dark">Kindrobots (c)2019</Footer>
				</Layout>
			</div>
		</ConnectedRouter>
		</DragDropContextProvider>
		</OidcProvider>
		</Provider>
	);
}

export default App;
