import * as ACTIONS from '../constants/actions';

const initialState = {
}

function banners(state = initialState, action) {
	switch (action.type) {
		case ACTIONS.BANNERS_LOAD_SUCCESS: {
			return { ...state,
				data: action.payload.banners,
			};
		}
		case ACTIONS.BANNERS_CRUD_UPDATE_SUCCESS: {
			return { ...state,
				data: state.data.map(t => { return t.uuid === action.payload.banner.uuid ? {
					...action.payload.banner,
				} : t}),
			};
		}
		case ACTIONS.BANNERS_CRUD_CREATE_SUCCESS: {
			let newdata = [...state.data];
			newdata.push({
				...action.payload.banner,
			});
			return { ...state,
				data: newdata,
			};
		}
		case ACTIONS.BANNERS_CRUD_DELETE_SUCCESS: {
			return { ...state,
				data: state.data.filter(t => t.uuid !== action.payload.bannerUuid),
			};
		}
		default: {
			return state;
		}
	}
}

export default banners;
