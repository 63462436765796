import { createUserManager } from 'redux-oidc';

import config from './config';

const userManagerConfig = {
  client_id: 'grammarerror.com.js',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${config.redirectUriPath}`,
  response_type: 'token id_token',
  scope: 'openid profile email api1',
  authority: config.authorityUrl,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${config.silentRedirectUriPath}`,
  automaticSilentRenew: true,
  monitorSession: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
  post_logout_redirect_uri: new URL(config.postLogoutRedirectPath, config.adminUrl).toString(),
  clockSkew: 300,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
