import React from 'react';

import { connect } from 'react-redux';
import { Alert, Form, Input, Button, Radio, Upload, Icon } from 'antd';

import { Prompt } from "react-router-dom";

import config from '../../utils/config';

import { bannersCrudCreate, bannersCrudUpdate, bannersCrudDelete, bannersEditorSetFormDirty } from '../../actions';

import '../../styles/banner_editor.css';

/* eslint react/forbid-prop-types: 0 */

class BannerFormComponent extends React.Component {

	constructor (props) {
		super(props);
		this.state = {
			type : props.banner.type,
			fileList: [],
			img: props.banner.bannerPath ? `${config.apiUrl}${this.props.banner.bannerPath}` : null,
			uploadHint: ''
		};
	}

	handleTypeChange = (evt) => {
		this.setState({ type: evt.target.value });
		this.props.bannersEditorSetFormDirty();
	};

	handleValueChange = () => {
		this.props.bannersEditorSetFormDirty();
	}

	handleDelete = (e) => {
		if (window.confirm('Are you sure?'))
			this.props.bannersCrudDelete(this.props.banner.uuid);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const banner = {...values, bannerData: this.state.img ? this.state.img.replace(/.+;base64,/, '') : null};
				if (this.props.banner.uuid)
					this.props.bannersCrudUpdate(banner);
				else
					this.props.bannersCrudCreate(banner);
			}
		});
	}

	render() {
		const {
			getFieldDecorator
		} = this.props.form;

		/*
		const formItemLayout = {
			labelCol: { span: 4 },
			wrapperCol: { span: 20 },
		};
		*/
		let imgSize = { x: 728, y: 90 };
		switch(this.state.type) {
			case 'desktop728x90':
				imgSize = { x: 728, y: 90 };
				break;
			case 'mobile300x250':
				imgSize = { x: 300, y: 250 };
				break;
			default:
				imgSize = { x: 728, y: 90 };
				break;
		}

		const uploadProps = {
	      beforeUpload: file => {
	      	const reader = new FileReader();
			reader.addEventListener("load", () => {
			    this.setState({
		          img: reader.result,
		        });
		        this.props.bannersEditorSetFormDirty();
			}, false);
			reader.readAsDataURL(file);
	        return false;
	      },
	      onChange: info => {
	      	let fileList = [...info.fileList];
	      	if (info.file.type !== 'image/png') {
	      		fileList = [];
	      		this.setState({fileList, uploadHint: 'Only png images are allowed'});
	      	} else {
	      		fileList = fileList.slice(-1);
	      		this.setState({fileList, uploadHint: ''});
	      	}
	      },
	    };

		return (
				<div>
				<Prompt
					when={this.props.isDirty}
					message={location => 'There are unsaved changes. Do you want to navigate away?'}
				/>
				<Form
					onSubmit={this.handleSubmit}
				>
					<div>
						<Button className="hor_btn" type="primary" disabled={!this.props.isDirty} htmlType="submit">
							Save
						</Button>
						{ this.props.banner.uuid && (
							<Button className="hor_btn" type="danger" onClick={this.handleDelete}>Delete</Button>
						)}
					</div>
					{ this.props.serverError && (
						<Alert
    					  message="Server Error"
      					  description={this.props.serverError.message}
      					  type="error"
      					  showIcon
    					/>
					)}
					<Form.Item
					label="Label"
					>
						{getFieldDecorator('label', {
						rules: [{ required: true, message: 'Label should not be empty' }],
						initialValue: this.props.banner.label
						})(
							<Input onChange={this.handleValueChange} />
						)}
					</Form.Item>
					<Form.Item
					label="Target"
					>
						{getFieldDecorator('bannerTarget', {
						rules: [{ required: true, message: 'Target should not be empty' }],
						initialValue: this.props.banner.bannerTarget
						})(
							<Input onChange={this.handleValueChange} />
						)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('type', {
							rules: [],
							initialValue: this.props.banner.type
						})(
							<Radio.Group onChange={this.handleTypeChange}>
						        <Radio value={'desktop728x90'}>Desktop, 728x90</Radio>
						        <Radio value={'mobile300x250'}>Mobile, 300x250</Radio>
						    </Radio.Group>
						)}
					</Form.Item>
					<div className="placeholder_parent">
					{ this.state.img ? (
						<div>
							<img width={imgSize.x} height={imgSize.y} alt={this.props.banner.label} src={this.state.img} />
						</div>
						) : (
						<div className={`${this.state.type}_placeholder`} >
							No image
						</div>
						)
					}
					</div>
					<Upload {...uploadProps} fileList={this.state.fileList}>
					    <Button>
					    	<Icon type="upload" /> Click to Upload
						</Button>
					</Upload>
					{ this.state.uploadHint && (
						<Alert
    					  message="Invalid Image"
      					  description={this.state.uploadHint}
      					  type="error"
      					  showIcon
    					/>
					)}
					<Form.Item>
						{getFieldDecorator('uuid', {
						rules: [],
						initialValue: this.props.banner.uuid
						})(
							<input type="hidden" />,
						)}
					</Form.Item>
				</Form>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		challenges: state.challenges.data,
		isDirty: state.bannersEditor.dirty,
		serverError: state.errors.saveError,
		banner: state.bannersEditor.banner,
	};
}

export default connect(mapStateToProps,
						{ bannersEditorSetFormDirty,
							bannersCrudCreate,
							bannersCrudUpdate,
							bannersCrudDelete,
						})(Form.create()(BannerFormComponent));
