import * as ReduxOidc from 'redux-oidc';
import userManager from './userManager';
// NOTE P* even here using store might be a bad style
import store from '../store';

// oidc is the part of redux-oidc state
export const isUserExpiredInternal = oidc => !!(oidc && oidc.user && oidc.user.expired);
// oidc is the part of redux-oidc state
export const isUserLoadingInternal = oidc => !!(oidc && oidc.isLoadingUser);
// oidc is the part of redux-oidc state
// eslint-disable-next-line arrow-body-style
const isUserAuthenticatedInternal = (oidc) => {
  return !!(oidc && oidc.user && !isUserLoadingInternal(oidc) && !isUserExpiredInternal(oidc));
};

// #region AUTH 'SERVICE'
export const signInRedirect = () => userManager.signinRedirect();
export const signOutRedirect = () => userManager.signoutRedirect();
export const loadUser = () => ReduxOidc.loadUser(store, userManager);
export const isUserAuthenticated = oidcPart => isUserAuthenticatedInternal(
  oidcPart || store.getState().oidc
);
export const isUserLoading = oidcPart => isUserLoadingInternal(oidcPart || store.getState().oidc);
export const getUserAccessToken = () => {
  const { oidc } = store.getState();
  return oidc && oidc.user && oidc.user.access_token;
};
// #endregion
