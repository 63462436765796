import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Avatar, Card, Row, Col } from 'antd';

import { userLogin, userLogout } from '../../actions/actions4auth';

const { Meta } = Card;

function Home(props) {
  const { user } = props;
  const logged = user && !user.expired;

  const onLogin = () => {
    props.userLogin();
  }

  const onLogout = () => {
    props.userLogout();
  }

  return logged ? (
    <div>
      <Row style={{ marginBottom: 16 }} type="flex" justify="center">
        <Col span={4}>
          <Link to="/tags">
            <Card hoverable style={{ width: 240 }} >
              <Meta avatar={<Avatar size={28} icon="tags" />} title="Tags Editor" />
            </Card>
          </Link>
        </Col>
        <Col span={4}>
          <Link to="/questions">
            <Card hoverable style={{ width: 240 }} >
                <Meta avatar={<Avatar size={28} icon="question-circle" />} title="Questions Editor" />
            </Card>
          </Link>
        </Col>
        <Col span={4}>
          <Link to="/challenges">
            <Card hoverable style={{ width: 240 }} >
                <Meta avatar={<Avatar size={28} icon="ordered-list" />} title="Challenges Editor" />
            </Card>
          </Link>
        </Col>
        <Col span={4}>
          <Link to="/banners">
            <Card hoverable style={{ width: 240 }} >
                <Meta avatar={<Avatar size={28} icon="upload" />} title="Banners Editor" />
            </Card>
          </Link>
        </Col>
        <Col span={4}>
          <Link to="/iframe">
            <Card hoverable style={{ width: 240 }} >
                <Meta avatar={<Avatar size={28} icon="pic-center" />} title="IFrame Code" />
            </Card>
          </Link>
        </Col>
        <Col span={4}>
          <Card onClick={onLogout} hoverable style={{ width: 240 }} >
              <Meta avatar={<Avatar size={28} icon="logout" />} title="Logout" />
          </Card>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
    <Row style={{ marginTop: 16 }} type="flex" justify="center">
      <Col>
        <Card onClick={onLogin} className="homePageContent" hoverable style={{ width: 240 }} >
          <Meta avatar={<Avatar size={28} icon="login" />} title="Login" />
        </Card>
      </Col>
    </Row>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export default connect(mapStateToProps, {
                      userLogin,
                      userLogout,
                    }
               )(Home);
