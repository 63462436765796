import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import * as AuthService from '../../utils/authService';

export default function (ComposedComponent) {
  class Authentication extends Component {
    componentWillMount() {
      if (!this.props.isUserAuthenticated && !this.props.isUserLoading) {
        this.props.push('/');
      }
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.isUserAuthenticated && !nextProps.isUserLoading) {
        this.props.push('/');
      }
    }

    render() {
      if (this.props.isUserLoading) return <Spin size="large" />;

      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      isUserAuthenticated: AuthService.isUserAuthenticated(state.oidc),
      isUserLoading: AuthService.isUserLoading(state.oidc)
    };
  }

  return connect(mapStateToProps, { push })(Authentication);
}
