import * as ACTIONS from '../constants/actions';

const initialState = {
}

function tags(state = initialState, action) {
	switch (action.type) {
		case ACTIONS.TAGS_LOAD_SUCCESS: {
			return { ...state,
				data: action.payload.tags,
			};
		}
		case ACTIONS.TAGS_CRUD_UPDATE_SUCCESS: {
			return { ...state,
				data: state.data.map(t => { return t.slug === action.payload.tagSlug ? {
					slug: action.payload.tag.slug,
					title: action.payload.tag.title,
					parentSlug: action.payload.tag.parentSlug,
				} : t}),
			};
		}
		case ACTIONS.TAGS_CRUD_CREATE_SUCCESS: {
			let newdata = [...state.data];
			newdata.push({
				slug: action.payload.tag.slug,
				title: action.payload.tag.title,
				parentSlug: action.payload.tag.parentSlug,
			});
			return { ...state,
				data: newdata,
			};
		}
		case ACTIONS.TAGS_CRUD_DELETE_SUCCESS: {
			return { ...state,
				data: state.data.filter(t => t.uuid !== action.payload.tagUuid),
			};
		}
		default: {
			return state;
		}
	}
}

export default tags;
