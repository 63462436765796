import * as ACTIONS from '../constants/actions';

const initialState = {
}

function questions(state = initialState, action) {
	switch (action.type) {
		case ACTIONS.QUESTIONS_LOAD_SUCCESS: {
			return { ...state,
				data: action.payload.questions,
			};
		}
		case ACTIONS.QUESTIONS_CRUD_UPDATE_SUCCESS: {
			return { ...state,
				data: state.data.map(t => { return t.slug === action.payload.questionSlug ? {
					...action.payload.question,
				} : t}),
			};
		}
		case ACTIONS.QUESTIONS_CRUD_CREATE_SUCCESS: {
			let newdata = [...state.data];
			newdata.push({
				...action.payload.question,
			});
			return { ...state,
				data: newdata,
			};
		}
		case ACTIONS.QUESTIONS_CRUD_DELETE_SUCCESS: {
			return { ...state,
				data: state.data.filter(t => t.uuid !== action.payload.questionUuid),
			};
		}
		case ACTIONS.TAGS_CRUD_DELETE_SUCCESS: {
			return { ...state,
				data: state.data.map(t => {
					if (t.tags.some(c => c.uuid == action.payload.tagUuid)){
						return {...t, tags: t.tags.filter(c => c.uuid != action.payload.tagUuid)};
					} else {
						return t;
					}
				}),
			};
		}
		default: {
			return state;
		}
	}
}

export default questions;
