import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { loadUser } from 'redux-oidc';

import createSagaMiddleware from 'redux-saga';

import createRootReducer from './reducer';

import rootSaga from './sagas';

import userManager from './utils/userManager';

/* eslint-disable no-console */
const loggerMiddleware = store => next => (action) => {
  console.log('Action type:', action.type);
  console.log('Action payload:', action.payload);
  console.log('State before:', store.getState());
  next(action);
  console.log('State after:', store.getState());
};

const history = createBrowserHistory();

function configureStore(preloadedState, sagaMiddleware) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
	      loggerMiddleware,
        routerMiddleware(history), // for dispatching history actions
	      sagaMiddleware
      ),
    ),
  )
  return store;
}

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({}, sagaMiddleware);

loadUser(store, userManager);

sagaMiddleware.run(rootSaga);

export default store;
export { history };
