import * as ActionTypes from '../constants/actions4banners';

export function bannersLoad() {
  return { type: ActionTypes.BANNERS_LOAD, payload: {} };
}
export function bannersLoadSuccess(banners) {
  return { type: ActionTypes.BANNERS_LOAD_SUCCESS, payload: { banners } };
}
export function bannersLoadFailure(errorMessage) {
  return { type: ActionTypes.BANNERS_LOAD_FAILURE, payload: { error: { message: errorMessage } } };
}

export function bannersEditorLoadById(bannerid) {
  return { type: ActionTypes.UI_BANNERSEDITOR_LOAD_BY_ID, payload: { bannerid } };
}

export function bannersEditorBeforeSelectBanner(banner) {
  return { type: ActionTypes.UI_BANNERSEDITOR_BEFORE_SELECT_BANNER, payload: { banner } };
}

export function bannersEditorAfterSelectBanner(banner) {
  return { type: ActionTypes.UI_BANNERSEDITOR_AFTER_SELECT_BANNER, payload: { banner } };
}

export function bannersEditorSetFormDirty() {
  return { type: ActionTypes.UI_BANNERSEDITOR_SET_FORM_DIRTY, payload: { } };
}

export function bannersCrudUpdate(banner) {
  return { type: ActionTypes.BANNERS_CRUD_UPDATE, payload: { banner } };
}
export function bannersCrudUpdateSuccess(banner) {
  return { type: ActionTypes.BANNERS_CRUD_UPDATE_SUCCESS, payload: { banner } };
}
export function bannersCrudUpdateFailure(errorMessage) {
  return { type: ActionTypes.BANNERS_CRUD_UPDATE_FAILURE, payload: { error: { message: errorMessage } } };
}

export function bannersCrudCreate(banner) {
  return { type: ActionTypes.BANNERS_CRUD_CREATE, payload: { banner } };
}
export function bannersCrudCreateSuccess(banner) {
  return { type: ActionTypes.BANNERS_CRUD_CREATE_SUCCESS, payload: { banner } };
}
export function bannersCrudCreateFailure(errorMessage) {
  return { type: ActionTypes.BANNERS_CRUD_CREATE_FAILURE, payload: { error: { message: errorMessage } } };
}

export function bannersCrudDelete(bannerUuid) {
  return { type: ActionTypes.BANNERS_CRUD_DELETE, payload: { bannerUuid } };
}
export function bannersCrudDeleteSuccess(bannerUuid) {
  return { type: ActionTypes.BANNERS_CRUD_DELETE_SUCCESS, payload: { bannerUuid } };
}
export function bannersCrudDeleteFailure(errorMessage) {
  return { type: ActionTypes.BANNERS_CRUD_DELETE_FAILURE, payload: { error: { message: errorMessage } } };
}
