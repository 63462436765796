import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Alert, Breadcrumb, Icon, InputNumber, Checkbox } from 'antd';

import ChallengeSelector from '../ChallengeSelector';

import { challengesLoad } from '../../actions';

import config from '../../utils/config';

import '../../styles/editor.css';

/* eslint react/forbid-prop-types: 0 */

class IFrameEditorComponent extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			slug: null,
			showintro: false,
			height: 250,
		}
	}
	componentDidMount() {
		if (!this.props.challenges) {
			this.props.challengesLoad();
		}
		window.addEventListener('message', this.onIFrameMessage);
	}

	onIFrameMessage = (e) => {
		if (e.data && e.data.id == 'gmsh') {
			this.setState({ height: e.data.h });
		}
	};

	onChallengeChange = (slug) => {
		this.setState({ slug });
	};

	onChangeIntro = (e) => {
		this.setState({showintro: e.target.checked});
	}

	render() {
		if (!this.props.loadError && !this.props.challenges)
			return <Icon type="loading" />;

		var show = this.state.showintro ? 'intro' : 'challenge';

		return (
			<div>
				<Breadcrumb>
					<Breadcrumb.Item>
					<Link to="/">Home</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
					IFrame Code
					</Breadcrumb.Item>
				</Breadcrumb>
				<div className="content">
					{this.props.loadError ?
					(<Alert type="error" message={this.props.loadError.message} />)
					: (
						<div className="editor_form">
							<p>Select challenge to generate iframe code:</p>
							<ChallengeSelector challenges={this.props.challenges} onChallengeChange={this.onChallengeChange} />
							<div className="small_margin">
								Start from intro: <Checkbox checked={this.state.showintro} onChange={this.onChangeIntro}></Checkbox>
							</div>
							{ this.state.slug ? (
									<React.Fragment>
										<div className="small_margin">
											<iframe id={this.state.slug}
													name={this.state.slug}
													src={`${config.webUrl}/embed/${show}/${this.state.slug}`}
													style={{width: "100%", border: "0px", overflow: "scroll", height: this.state.height }}>
											</iframe>
										</div>
										<div className="small_margin">
											{`<iframe id="${this.state.slug}"
												name="${this.state.slug}"
												src="${config.webUrl}/embed/${show}/${this.state.slug}"
												style="width: 100%; border: 0px; overflow: scroll;"></iframe><script>function _hm(e) { if (e.data && e.data.id == \'gmsh\') window.document.getElementById("${this.state.slug}").style.height = e.data.h + \'px\'; };window.addEventListener(\'message\', _hm);</script>`}
										</div>
									</React.Fragment>
								) : (
									<div>None selected</div>
								)
							}
						</div>
					)
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		challenges: state.challenges.data,
		loadError: state.errors.loadError,
	};
}

export default connect(mapStateToProps, {
											challengesLoad,
										}
				)(IFrameEditorComponent);
