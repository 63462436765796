import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from 'react-redux';
import { Alert, Form, Input, Button, Tabs, Select } from 'antd';

import { Prompt } from "react-router-dom";

import ReactMde from "react-mde";
import ReactMarkdown from 'react-markdown';
import "react-mde/lib/styles/css/react-mde-all.css";

import { tagsCrudCreate, tagsCrudUpdate, tagsCrudDelete, tagsEditorSetFormDirty } from '../../actions';

import { questionType2DisplayName } from '../../utils/editorHelpers';

import TagSelector from '../TagSelector';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

/* eslint react/forbid-prop-types: 0 */

class TagFormComponent extends React.Component {

	constructor (props) {
		super(props);
		this.state = {
			description: props.tag ? props.tag.description : null,
			shortDescription: props.tag ? props.tag.shortDescription : null,
			tab: 'write',
		};
	}

	handleDescriptionChange = (description) => {
		this.setState({ description });
		this.props.tagsEditorSetFormDirty();
	};

	handleShortDescriptionChange = (shortDescription) => {
		this.setState({ shortDescription });
		this.props.tagsEditorSetFormDirty();
	};

	handleTabChange = tab => {
		this.setState({ tab });
	};

	handleValueChange = () => {
		this.props.tagsEditorSetFormDirty();
	}

	handleDelete = (e) => {
		if (window.confirm('Are you sure?'))
			this.props.tagsCrudDelete(this.props.tag.uuid);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				if (this.props.tag.uuid)
					this.props.tagsCrudUpdate({...values, description: this.state.description, shortDescription: this.state.shortDescription});
				else
					this.props.tagsCrudCreate({...values, description: this.state.description, shortDescription: this.state.shortDescription});
			}
		});
	}

	render() {
		const {
			getFieldDecorator
		} = this.props.form;

		let sortedParents = this.props.tags.filter(tag => tag.slug !== this.props.tag.slug).map(tag => { return {...tag}; });
		sortedParents.sort((a,b) => {
			const au = a.title.toUpperCase();
			const bu = b.title.toUpperCase();
			return au < bu ? -1 : (au === bu ? 0 : 1);
		});

		const allquestions = this.props.questions ? this.props.questions.map(q => <Option key={q.slug}>{`${q.slug} / ${questionType2DisplayName(q.type)}`}</Option>) : null;

		/*
		const formItemLayout = {
			labelCol: { span: 4 },
			wrapperCol: { span: 20 },
		};
		*/
		return (
			<div>
				<Prompt
					when={this.props.isDirty}
					message={location => 'There are unsaved changes. Do you want to navigate away?'}
				/>
				<Form
					onSubmit={this.handleSubmit}
				>
					<h2>
						{this.props.tag.title}
						<Button className="hor_btn" type="primary" disabled={!this.props.isDirty} htmlType="submit">
							Save
						</Button>
						<Button className="hor_btn" type="danger" onClick={this.handleDelete} disabled={this.props.tags.some(tag => tag.parentSlug === this.props.tag.slug)}>
							{this.props.tag.uuid ? 'Delete' : 'Cancel' }
						</Button>
					</h2>
					{ this.props.serverError && (
						<Alert
    					  message="Server Error"
      					  description={this.props.serverError.message}
      					  type="error"
      					  showIcon
    					/>
					)}
					<Tabs defaultActiveKey="1">
						<TabPane tab="Content" key="1">
							<Form.Item
							label="Title"
							>
							{getFieldDecorator('title', {
								rules: [{ required: true, message: 'Title should not be empty' }],
								initialValue: this.props.tag.title
							})(
								<Input onChange={this.handleValueChange} />
							)}
							</Form.Item>
							<div>Short Description:</div>
							<ReactMde
								onChange={this.handleShortDescriptionChange}
								onTabChange={this.handleTabChange}
								value={this.state.shortDescription}
								generateShortDescriptionPreview={shortDescription => {
									return new Promise(resolve => {
										let a = document.createElement('div');
										ReactDOM.render(<ReactMarkdown source={shortDescription} escapeHtml={false} />, a, () => {
											resolve(a.innerHTML);
										});
									});
								}}
								selectedTab={this.state.tab}
							/>
							<div>Description:</div>
							<ReactMde
								onChange={this.handleDescriptionChange}
								onTabChange={this.handleTabChange}
								value={this.state.description}
								generateDescriptionPreview={description => {
									return new Promise(resolve => {
										let a = document.createElement('div');
										ReactDOM.render(<ReactMarkdown source={description} escapeHtml={false} />, a, () => {
											resolve(a.innerHTML);
										});
									});
								}}
								selectedTab={this.state.tab}
							/>
							<Form.Item
								label="Description Urls"
							>
							{getFieldDecorator('descriptionUrls', {
								rules: [],
								initialValue: this.props.tag.descriptionUrls
							})(
								<TextArea onChange={this.handleValueChange} rows={4} />
							)}
							</Form.Item>
							<Form.Item
								label="License"
							>
							{getFieldDecorator('license', {
								rules: [],
								initialValue: this.props.tag.license
							})(
								<Input onChange={this.handleValueChange} />
							)}
							</Form.Item>
						</TabPane>
						<TabPane tab="Meta" key="2">
							<Form.Item
							label="Slug"
							>
							{getFieldDecorator('slug', {
								rules: [{ required: true, message: 'Slug should not be empty' }],
								initialValue: this.props.tag.slug
							})(
								<Input onChange={this.handleValueChange} />
							)}
							</Form.Item>
								<Form.Item
									label="Parent Tag"
								>
								{getFieldDecorator('parentSlug', {
									rules: [],
									initialValue: this.props.tag.parentSlug
								})(
									<TagSelector
										{...this.props}
										onChange={this.handleValueChange}
									/>
								)}
								</Form.Item>
						</TabPane>
						<TabPane tab="Usage" key="3">
							NOTE: CHANGES ARE NOT APPLIED (for now?)
							<Form.Item label="Used in Questions:">
								{getFieldDecorator('__questions', {
									rules: [],
									initialValue: this.props.questions.filter(q => q.tags.some(t => t.slug === this.props.tag.slug)).map(t => t.slug)
								})(
									<Select
										allowClear
										style={{ width: 500 }}
										dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
										filterOption={true}
										placeholder="Add questions to this tag"
										onChange={this.handleValueChange}
										showSearch={true}
										mode="multiple"
									>
										{allquestions}
									</Select>
								)}
							</Form.Item>
						</TabPane>
					</Tabs>
					<Form.Item>
					{getFieldDecorator('uuid', {
						rules: [],
						initialValue: this.props.tag.uuid
					})(
						<input type="hidden" />,
					)}
					</Form.Item>
				</Form>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		tags: state.tags.data,
		questions: state.questions.data,
		isDirty: state.tagsEditor.dirty,
		serverError: state.errors.saveError,
		tag: state.tagsEditor.tag,
	};
}

export default connect(mapStateToProps,
												{ tagsEditorSetFormDirty,
													tagsCrudCreate,
													tagsCrudUpdate,
													tagsCrudDelete,
												})(Form.create()(TagFormComponent));
