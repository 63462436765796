import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import config from '../utils/config';

import { isUserAuthenticated, getUserAccessToken } from '../utils/authService';

const httpLink = new HttpLink({ uri: new URL('graphql', config.apiUrl).href });

const authLink = setContext((request, previousContext) => {
  	const { headers } = previousContext;
  	const authenticated = isUserAuthenticated();

	if (!authenticated) return { headers };

	// If the user is authenticated, then augment headers
	const token = getUserAccessToken();
	// return the headers to the context so httpLink can read them
	const result = { headers: { ...headers } };
	if (token)
		result.headers.authorization = `Bearer ${token}`;
  	return result;
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    dataIdFromObject: object => {
        return object.uuid;
    },
  }),
});

export default apolloClient;
