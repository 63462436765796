var regex = /\n\n/g;

export function convertQuestionWithOptions(question) {
  let options = question.options.sort((a,b) => a.index > b.index);
  let markdown = `${question.task}\n`;
  markdown += options.reduce((accumulator, option, index) => {
  	const c = option.isCorrect ? 'x' : ' ';
  	const current = `- [${c}] ${option.value}\n`;
  	return accumulator + current;
  }, '');
  let comment = '';
  comment += options.reduce((accumulator, option, index) => {
  	const current = `${option.comment}${index !== options.length - 1 ? '\n' : ''}`;
  	return accumulator + current;
  }, '');
  return `${markdown}\n---\n${comment}`.replace(regex, '\n').trim();
}

export function convertQuestionWithParts(question) {
  let markdown = `${question.task}\n`;
  markdown += question.parts.reduce((accumulator, part, index) => {
  	const current = `${part.content.replace(/\(\?[0-9]\)/g, '(?)')}\n`;
  	return accumulator + current;
  }, '');
  let comment = '';
  comment += question.parts.reduce((accumulator, part, index) => {
  	const current = `${part.comment}${index !== part.length - 1 ? '\n' : ''}`;
  	return accumulator + current;
  }, '');
  return `${markdown}\n---\n${comment}`.replace(regex, '\n').trim();
}