import * as ACTIONS from '../constants/actions';

const initialState = {
  tag: null,
  dirty: false,
  loaded: {},
}

function tagsEditor(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.UI_TAGSEDITOR_AFTER_SELECT_TAG: {
      return { ...state,
        tag: action.payload.tag,
        dirty: false,
      };
    }
    case ACTIONS.UI_TAGSEDITOR_SET_FORM_DIRTY: {
      return { ...state,
        dirty: true,
      };
    }
    case ACTIONS.TAGS_CRUD_CREATE_SUCCESS: {
      return { ...state,
        tag: action.payload.tag,
        loaded: {...state.loaded, [action.payload.tag.slug]:action.payload.tag },
        dirty: false,
      };
    }
    case ACTIONS.TAGS_CRUD_UPDATE_SUCCESS: {
      let loaded = {...state.loaded};
      Object.keys(loaded).forEach(k => {
        if (loaded[k].uuid === action.payload.tag.uuid) {
          loaded[k] = action.payload.tag;
        }
      });
      return { ...state,
        tag: action.payload.tag,
        loaded,
        dirty: false,
      };
    }
    case ACTIONS.TAGS_CRUD_DELETE_SUCCESS: {
      let loaded = {...state.loaded};
      Object.keys(loaded).forEach(k => {
        if (loaded[k].uuid === action.payload.tagUuid) {
          delete loaded[k];
        }
      });
      return { ...state,
        tag: null,
        loaded,
        dirty: false,
      };
    }
    default: {
      return state;
    }
  }
}

export default tagsEditor;
