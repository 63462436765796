export const BANNERS_LOAD = 'BANNERS_LOAD';
export const BANNERS_LOAD_SUCCESS = 'BANNERS_LOAD_SUCCESS';
export const BANNERS_LOAD_FAILURE = 'BANNERS_LOAD_FAILURE';

export const UI_BANNERSEDITOR_BEFORE_SELECT_BANNER = 'UI_BANNERSEDITOR_BEFORE_SELECT_BANNER';
export const UI_BANNERSEDITOR_AFTER_SELECT_BANNER = 'UI_BANNERSEDITOR_AFTER_SELECT_BANNER';

export const UI_BANNERSEDITOR_SET_FORM_DIRTY = 'UI_BANNERSEDITOR_SET_FORM_DIRTY';

export const BANNERS_CRUD_UPDATE = 'BANNERS_CRUD_UPDATE';
export const BANNERS_CRUD_UPDATE_SUCCESS = 'BANNERS_CRUD_UPDATE_SUCCESS';
export const BANNERS_CRUD_UPDATE_FAILURE = 'BANNERS_CRUD_UPDATE_FAILURE';

export const BANNERS_CRUD_CREATE = 'BANNERS_CRUD_CREATE';
export const BANNERS_CRUD_CREATE_SUCCESS = 'BANNERS_CRUD_CREATE_SUCCESS';
export const BANNERS_CRUD_CREATE_FAILURE = 'BANNERS_CRUD_CREATE_FAILURE';

export const BANNERS_CRUD_DELETE = 'BANNERS_CRUD_DELETE';
export const BANNERS_CRUD_DELETE_SUCCESS = 'BANNERS_CRUD_DELETE_SUCCESS';
export const BANNERS_CRUD_DELETE_FAILURE = 'BANNERS_CRUD_DELETE_FAILURE';

export const UI_BANNERSEDITOR_LOAD_BY_ID = 'UI_BANNERSEDITOR_LOAD_BY_ID';
