import React from 'react';
import { Select } from 'antd';
const { Option } = Select;

class ChallengeSelectorComponent extends React.Component {

  onChange = (value) => {
    if (this.props.onChallengeChange) this.props.onChallengeChange(value);
  }

  render() {
    return (
      <Select style={{ width: 300 }} onChange={this.onChange}>
        {this.props.challenges.map(ch => <Option value={ch.slug}>{ch.title}</Option>)}
      </Select>
    );
  }
}

export default ChallengeSelectorComponent;
