import React from 'react';
import { processSilentRenew } from 'redux-oidc';

class SilentRenewPageComponent extends React.Component {
  componentWillMount() {
    processSilentRenew();
  }

  render() {
    return (
      <div>
          Silent renew...
      </div>
    );
  }
}

export default SilentRenewPageComponent;
