import { push } from 'connected-react-router';
import * as ReduxOidc from 'redux-oidc';
import * as AuthService from '../utils/authService';

import { take, takeLatest, takeEvery, put, call, all, select, cancel } from 'redux-saga/effects';

import { loadTags,
         loadTagBySlug,
         createTag,
         updateTag,
         deleteTag,
         loadQuestions,
         loadQuestionBySlug,
         createQuestion,
         updateQuestion,
         deleteQuestion,
         loadChallenges,
         loadChallengeBySlug,
         createChallenge,
         updateChallenge,
         deleteChallenge,
         loadBanners,
         loadBannerById,
         createBanner,
         updateBanner,
         deleteBanner,
       } from '../api/graphql';

import * as ActionTypes from '../constants/actions';
import * as ActionCreators from '../actions/index';

const Log = console.log;

// #region AUTH
//    A part of this flow is in fact in redux-oidc

// NOTE V> Maybe, it's worth to avoid using redux-oidc 'loadUser' helper completely
//      because it hides oidc-client callbacks

function* nextUserLogin() {
  Log('nextUserLogin');

  yield call(AuthService.signInRedirect);
}

function* nextUserIsNotAuthenticated() {
  Log('nextUserIsNotAuthenticated');
  // NOTE V>> If still no user here - user is not authenticated, this means that
  //      either he is not logged in or silent renew did not work; in both cases we need
  //      to provide some re-login experince, we will redirect to login page for now,
  //      this should be reworked late as it's an awful user experience
  // NOTE V>> To redirect like this we need 'resume' user flow in place
  // NOTE V> Maybe, it's worth to dispatch a redux action here
  yield call(AuthService.signInRedirect);
}

// The action is used to block
function* nextWaitForUserToLoad() {
  Log('nextWaitForUserToLoad');
  try {
    // NOTE maybe it's worth adding a race effect to limit wait
    //      for the action result to cover "nothing ever happens" case
    //        var {error, success} = yield race({
    //          success: take(LOGIN_SUCCESS),
    //          error: take(LOGIN_ERROR)
    //        });
    const action = yield take([ReduxOidc.USER_FOUND, ReduxOidc.USER_EXPIRED]);

    if (ReduxOidc.USER_EXPIRED === action.type) yield call(nextUserIsNotAuthenticated);
  } catch (ex) {
    // Both 'take' effect creator and generator runner 'next' function throw
    // TODO V>> add proper logging
  }
}

function* nextUserLoad() {
  Log('nextUserLoad');

  try {
    yield call(AuthService.loadUser);

    yield* nextWaitForUserToLoad();
  } catch (ex) {
    // oidc LOAD_USER_ERROR should already have been dispatched by now
    // https://github.com/maxmantz/redux-oidc/blob/master/src/helpers/loadUser.js#L29
  }
}

function* ensureUserAuthenticated() {
  Log('ensureUserAuthenticated');

  if (AuthService.isUserAuthenticated()) return;
  // return;

  if (AuthService.isUserLoading()) {
    yield* nextWaitForUserToLoad();
  } else {
    yield* nextUserLoad();
  }

  // yield* nextUserIsNotAuthenticated(/* FIXME: V> Pass a proper action here */);
}

// #region Logout

function* nextUserLogout() {
  Log('nextUserLogout');

  if (!AuthService.isUserAuthenticated()) return;

  // NOTE V>> using redux-oidc makes sagas logic not 'watertight',
  //      here and there we rely on redux-oidc side effects,
  //      maybe it is worth to rewrite things w/o redux-oidc completely
  yield call(AuthService.signOutRedirect);

  // OidcProvider is hooked to userManager signout callback and
  // will call ReduxOidc.userSignedOut()

  // TODO !P2 Testing: make sure that the token ends up deleted no matter what
  //      e.g. if server communication is broken
  //      1. Set a breakpoint in oidc-client removeUser
  //      2. Write a test
}

// #endregion

// FIXME: !P2 Check if we need special logout logic here
//        ~ _ing, _success, _failure

// TODO: !P2 Check if we need 'loginWatcher' logic here
// https://start.jcolemorrison.com/react-and-redux-sagas-authentication-app-tutorial-part-2/

// TODO: !P2 Check if we need redux-oidc at all after this

// TODO: !P2 Add logout saga

// TODO: V> Other projects will also need signInSaga

// TODO: V> Write tests

// #endregion AUTH

function* nextTagsLoad() {
  try {
    const queryresult = yield call(loadTags);
    yield put(ActionCreators.tagsLoadSuccess(queryresult.data.tags));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.tagsLoadFailure(`Tags failed to load: ${ex.message}`));
  }
}

function* nextLoadTagBySlug(action) {
  const queryresult = yield call(loadTagBySlug, action.payload.tagslug);
  const loadedTag = queryresult.data.tagBySlug;
  if (loadedTag.parent) {
      //fix server format
      loadedTag.parentSlug = loadedTag.parent.slug;
  }
  yield put(ActionCreators.tagsEditorAfterSelectTag(loadedTag));
}

function* nextTagCreate(action) {
  try {
    const queryresult = yield call(createTag, action.payload.tag);
    yield put(ActionCreators.tagsCrudCreateSuccess(queryresult.data.createTag));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.tagsCrudCreateFailure(`Failed to create tag: ${ex.message}`));
  }
}

function* nextTagUpdate(action) {
  try {
    const queryresult = yield call(updateTag, action.payload.tag);
    yield put(ActionCreators.tagsCrudUpdateSuccess(queryresult.data.updateTag));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.tagsCrudUpdateFailure(`Failed to update tag: ${ex.message}`));
  }
}

function* nextTagDelete(action) {
  try {
    const queryresult = yield call(deleteTag, action.payload.tagUuid);
    yield put(ActionCreators.tagsCrudDeleteSuccess(queryresult.data.deleteTag));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.tagsCrudDeleteFailure(`Failed to delete tag: ${ex.message}`));
  }
}

const getTagsEditorDirty = state => state.tagsEditor.dirty;
const getLoadedTags = state => state.tagsEditor.loaded;

function* nextBeforeSelectTag(action) {
  const isDirty = yield select(getTagsEditorDirty);
  if (isDirty) {
    const isok = yield call(window.confirm, "There are unsaved changes. Do you want to navigate away?");
    if (!isok) {
      yield cancel();
      return;
    }
  }
  const loaded = yield select(getLoadedTags);
  let loadedTag = loaded[action.payload.tag.slug];
  if (!loadedTag) {
    if (action.payload.tag.uuid) {
      const queryresult = yield call(loadTagBySlug, action.payload.tag.slug);
      loadedTag = queryresult.data.tagBySlug;
    } else {
      //do not load for new tag (without uuid)
      loadedTag = action.payload.tag;
    }
  }
  if (loadedTag.parent) {
      //fix server format
      loadedTag.parentSlug = loadedTag.parent.slug;
  }
  yield put(push(`/tags/${loadedTag.slug}`));
  yield put(ActionCreators.tagsEditorAfterSelectTag(loadedTag));
}

function* nextQuestionsLoad() {
  try {
    const queryresult = yield call(loadQuestions);
    yield put(ActionCreators.questionsLoadSuccess(queryresult.data.questionsByAuthor));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.questionsLoadFailure(`Questions failed to load: ${ex.message}`));
  }
}

function* nextLoadQuestionBySlug(action) {
  const queryresult = yield call(loadQuestionBySlug, action.payload.questionslug);
  const loadedQuestion = queryresult.data.questionByQuestionSlug;
  yield put(ActionCreators.questionsEditorAfterSelectQuestion(loadedQuestion));
}

function* nextQuestionCreate(action) {
  try {
    const queryresult = yield call(createQuestion, action.payload.question);
    //convert challenge to challengeinput expected by mutation
    const updated = action.payload.addedTo.map(c => {
      return {...c,
                  questions: [...c.questions.map(q => q.uuid), queryresult.data.createQuestion.uuid],
                  tags: c.tags.map(t => t.uuid),
                  banners: c.banners.map(b => b.uuid)};
    });
    yield all(updated.map(u => call(updateChallenge, u)));
    yield put(ActionCreators.questionsCrudCreateSuccess(queryresult.data.createQuestion, action.payload.addedTo));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.questionsCrudCreateFailure(`Failed to create question: ${ex.message}`));
  }
}

function* nextQuestionUpdate(action) {
  try {
    const queryresult = yield call(updateQuestion, action.payload.question);
    //convert challenge to challengeinput expected by mutation
    const updated = [...action.payload.addedTo.map(c => {
                          return {...c,
                            questions: [...c.questions.map(q => q.uuid), action.payload.question.uuid],
                            tags: c.tags.map(t => t.uuid),
                            banners: c.banners.map(b => b.uuid)};
                        }),
                     ...action.payload.removedFrom.map(c => {
                         return {...c,
                              questions: c.questions.filter(q => q.uuid != action.payload.question.uuid).map(q => q.uuid),
                              tags: c.tags.map(t => t.uuid),
                              banners: c.banners.map(b => b.uuid)};
                        })
                    ];
    yield all(updated.map(u => call(updateChallenge, u)));
    yield put(ActionCreators.questionsCrudUpdateSuccess(queryresult.data.updateQuestion, action.payload.removedFrom, action.payload.addedTo));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.questionsCrudUpdateFailure(`Failed to update question: ${ex.message}`));
  }
}

function* nextQuestionDelete(action) {
  try {
    const queryresult = yield call(deleteQuestion, action.payload.questionUuid);
    yield put(ActionCreators.questionsCrudDeleteSuccess(queryresult.data.deleteQuestion));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.questionsCrudDeleteFailure(`Failed to delete question: ${ex.message}`));
  }
}

const getQuestionsEditorDirty = state => state.questionsEditor.dirty;
const getLoadedQuestions = state => state.questionsEditor.loaded;

function* nextBeforeSelectQuestion(action) {
  const isDirty = yield select(getQuestionsEditorDirty);
  if (isDirty) {
    const isok = yield call(window.confirm, "There are unsaved changes. Do you want to navigate away?");
    if (!isok) {
      yield cancel();
      return;
    }
  }
  const loaded = yield select(getLoadedQuestions);
  let loadedQuestion = loaded[action.payload.question.slug];
  if (!loadedQuestion) {
    if (action.payload.question.uuid) {
      const queryresult = yield call(loadQuestionBySlug, action.payload.question.slug);
      loadedQuestion = queryresult.data.questionByQuestionSlug;
    } else {
      //do not load for new question (without uuid)
      loadedQuestion = action.payload.question;
    }
  }
  yield put(push(`/questions/${loadedQuestion.slug}`));
  yield put(ActionCreators.questionsEditorAfterSelectQuestion(loadedQuestion));
}

function* nextChallengesLoad() {
  try {
    const queryresult = yield call(loadChallenges);
    yield put(ActionCreators.challengesLoadSuccess(queryresult.data.challengesByAuthor));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.challengesLoadFailure(`Challenges failed to load: ${ex.message}`));
  }
}

function* nextLoadChallengeBySlug(action) {
  const queryresult = yield call(loadChallengeBySlug, action.payload.challengeslug);
  const loadedChallenge = queryresult.data.challengeBySlug;
  yield put(ActionCreators.challengesEditorAfterSelectChallenge(loadedChallenge));
}

function* nextChallengeCreate(action) {
  try {
    const queryresult = yield call(createChallenge, action.payload.challenge);
    yield put(ActionCreators.challengesCrudCreateSuccess(queryresult.data.createChallenge));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.challengesCrudCreateFailure(`Failed to create challenge: ${ex.message}`));
  }
}

function* nextChallengeUpdate(action) {
  try {
    const queryresult = yield call(updateChallenge, action.payload.challenge);
    yield put(ActionCreators.challengesCrudUpdateSuccess(queryresult.data.updateChallenge));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.challengesCrudUpdateFailure(`Failed to update challenge: ${ex.message}`));
  }
}

function* nextChallengeDelete(action) {
  try {
    const queryresult = yield call(deleteChallenge, action.payload.challengeUuid);
    yield put(ActionCreators.challengesCrudDeleteSuccess(queryresult.data.deleteChallenge));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.challengesCrudDeleteFailure(`Failed to delete challenge: ${ex.message}`));
  }
}

const getChallengesEditorDirty = state => state.challengesEditor.dirty;
const getLoadedChallenges = state => state.challengesEditor.loaded;

function* nextBeforeSelectChallenge(action) {
  const isDirty = yield select(getChallengesEditorDirty);
  if (isDirty) {
    const isok = yield call(window.confirm, "There are unsaved changes. Do you want to navigate away?");
    if (!isok) {
      yield cancel();
      return;
    }
  }
  const loaded = yield select(getLoadedChallenges);
  let loadedChallenge = loaded[action.payload.challenge.slug];
  if (!loadedChallenge) {
    if (action.payload.challenge.uuid) {
      const queryresult = yield call(loadChallengeBySlug, action.payload.challenge.slug);
      loadedChallenge = queryresult.data.challengeBySlug;
    } else {
      //do not load for new challenge (without uuid)
      loadedChallenge = action.payload.challenge;
    }
  }
  yield put(push(`/challenges/${loadedChallenge.slug}`));
  yield put(ActionCreators.challengesEditorAfterSelectChallenge(loadedChallenge));
}

function* nextBannersLoad() {
  try {
    const queryresult = yield call(loadBanners);
    yield put(ActionCreators.bannersLoadSuccess(queryresult.data.bannersByAuthor));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.bannersLoadFailure(`Banners failed to load: ${ex.message}`));
  }
}

function* nextLoadBannerById(action) {
  const queryresult = yield call(loadBannerById, action.payload.bannerUuid);
  const loadedBanner = queryresult.data.bannerById;
  yield put(ActionCreators.bannersEditorAfterSelectBanner(loadedBanner));
}

function* nextBannerCreate(action) {
  try {
    const queryresult = yield call(createBanner, action.payload.banner);
    yield put(ActionCreators.bannersCrudCreateSuccess(queryresult.data.createBanner));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.bannersCrudCreateFailure(`Failed to create banner: ${ex.message}`));
  }
}

function* nextBannerUpdate(action) {
  try {
    const queryresult = yield call(updateBanner, action.payload.banner);
    yield put(ActionCreators.bannersCrudUpdateSuccess(queryresult.data.updateBanner));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.bannersCrudUpdateFailure(`Failed to update banner: ${ex.message}`));
  }
}

function* nextBannerDelete(action) {
  try {
    const queryresult = yield call(deleteBanner, action.payload.bannerUuid);
    yield put(ActionCreators.bannersCrudDeleteSuccess(queryresult.data.deleteBanner));
  } catch (ex) {
    Log(ex);
    yield put(ActionCreators.bannersCrudDeleteFailure(`Failed to delete banner: ${ex.message}`));
  }
}

const getBannersEditorDirty = state => state.bannersEditor.dirty;
const getLoadedBanners = state => state.bannersEditor.loaded;

function* nextBeforeSelectBanner(action) {
  const isDirty = yield select(getBannersEditorDirty);
  if (isDirty) {
    const isok = yield call(window.confirm, "There are unsaved changes. Do you want to navigate away?");
    if (!isok) {
      yield cancel();
      return;
    }
  }
  const loaded = yield select(getLoadedBanners);
  let loadedBanner = loaded[action.payload.banner.uuid];
  if (!loadedBanner) {
    if (action.payload.banner.uuid) {
      const queryresult = yield call(loadBannerById, action.payload.banner.uuid);
      loadedBanner = queryresult.data.bannerById;
    } else {
      //do not load for new banner (without uuid)
      loadedBanner = action.payload.banner;
    }
  }
  yield put(push(`/banners/${loadedBanner.uuid}`));
  yield put(ActionCreators.bannersEditorAfterSelectBanner(loadedBanner));
}

export default function* root() {
  yield takeLatest(ActionTypes.USER_LOGIN, nextUserLogin);
  yield takeLatest(ActionTypes.USER_LOAD, nextUserLoad);
  yield takeLatest(ActionTypes.USER_LOGOUT, nextUserLogout);

  yield takeLatest(ActionTypes.TAGS_LOAD, nextTagsLoad);
  yield takeLatest(ActionTypes.UI_TAGSEDITOR_LOAD_BY_SLUG, nextLoadTagBySlug);

  yield takeLatest(ActionTypes.UI_TAGSEDITOR_BEFORE_SELECT_TAG, nextBeforeSelectTag);

  yield takeEvery(ActionTypes.TAGS_CRUD_CREATE, nextTagCreate);
  yield takeEvery(ActionTypes.TAGS_CRUD_UPDATE, nextTagUpdate);
  yield takeEvery(ActionTypes.TAGS_CRUD_DELETE, nextTagDelete);

  yield takeLatest(ActionTypes.QUESTIONS_LOAD, nextQuestionsLoad);
  yield takeLatest(ActionTypes.UI_QUESTIONSEDITOR_LOAD_BY_SLUG, nextLoadQuestionBySlug);

  yield takeLatest(ActionTypes.UI_QUESTIONSEDITOR_BEFORE_SELECT_QUESTION, nextBeforeSelectQuestion);

  yield takeEvery(ActionTypes.QUESTIONS_CRUD_CREATE, nextQuestionCreate);
  yield takeEvery(ActionTypes.QUESTIONS_CRUD_UPDATE, nextQuestionUpdate);
  yield takeEvery(ActionTypes.QUESTIONS_CRUD_DELETE, nextQuestionDelete);

  yield takeLatest(ActionTypes.CHALLENGES_LOAD, nextChallengesLoad);
  yield takeLatest(ActionTypes.UI_CHALLENGESEDITOR_LOAD_BY_SLUG, nextLoadChallengeBySlug);

  yield takeLatest(ActionTypes.UI_CHALLENGESEDITOR_BEFORE_SELECT_CHALLENGE, nextBeforeSelectChallenge);

  yield takeEvery(ActionTypes.CHALLENGES_CRUD_CREATE, nextChallengeCreate);
  yield takeEvery(ActionTypes.CHALLENGES_CRUD_UPDATE, nextChallengeUpdate);
  yield takeEvery(ActionTypes.CHALLENGES_CRUD_DELETE, nextChallengeDelete);

  yield takeLatest(ActionTypes.BANNERS_LOAD, nextBannersLoad);
  yield takeLatest(ActionTypes.UI_BANNERSEDITOR_LOAD_BY_ID, nextLoadBannerById);

  yield takeLatest(ActionTypes.UI_BANNERSEDITOR_BEFORE_SELECT_BANNER, nextBeforeSelectBanner);

  yield takeEvery(ActionTypes.BANNERS_CRUD_CREATE, nextBannerCreate);
  yield takeEvery(ActionTypes.BANNERS_CRUD_UPDATE, nextBannerUpdate);
  yield takeEvery(ActionTypes.BANNERS_CRUD_DELETE, nextBannerDelete);
}
