import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from 'react-redux';
import { Alert, Form, Input, Button, Select, TreeSelect, Tabs, Checkbox } from 'antd';

import { Prompt } from "react-router-dom";

import ReactMde from "react-mde";
import ReactMarkdown from 'react-markdown';

import "react-mde/lib/styles/css/react-mde-all.css";

import { questionsCrudCreate, questionsCrudUpdate, questionsCrudDelete, questionsEditorSetFormDirty, challengesCrudUpdate } from '../../actions';
import { convertQuestionWithOptions, convertQuestionWithParts } from '../../utils/formatConverters';
import { chopTrees } from '../../utils/tagsHelpers';

import { SAMPLE_SINGLE_CHOICE, SAMPLE_MULTI_CHOICE, SAMPLE_FILL_BLANKS, SAMPLE_DROP_DOWN, SAMPLE_DRAG_AND_DROP } from '../../constants/sampleQuestions';

import { Question, Answer, QuestionDisplay } from '@kindrobots/web.fe.ui';

const { Option } = Select;
const { TabPane } = Tabs;

/* eslint react/forbid-prop-types: 0 */
/*
uuid
slug
task
type
*/

class QuestionFormComponent extends React.Component {

	constructor (props) {
		super(props);
		let task = props.question ? props.question.text : null;
		if (props.question && props.question.options && props.question.options.length){
			task = convertQuestionWithOptions(props.question);
			props.questionsEditorSetFormDirty();
		}
		if (props.question && props.question.parts && props.question.parts.length){
			task = convertQuestionWithParts(props.question);
			props.questionsEditorSetFormDirty();
		}
		this.state = {
			task,
			type: props.question ? props.question.type : null,
			tabTask: 'write',
			answer: {
        		answerElements: [],
      		},
		};
	}

    onSetAnswer = (e) => {
      const answer = new Answer({question: {...this.props.question, type:this.state.type}, answerElements: this.state.answer.answerElements});
      answer.setAnswer(e);
      this.setState({
        answer,
        showValidationMessage: false
      });
    };

	handleTaskChange = (task) => {
		this.setState({ task });
		this.props.questionsEditorSetFormDirty();
	};

	handleTaskTabChange = tabTask => {
		this.setState({ tabTask });
	};

	handleValueChange = () => {
		this.props.questionsEditorSetFormDirty();
	}

	handleTypeChange = (type) => {
		const samples = [SAMPLE_DROP_DOWN, SAMPLE_DRAG_AND_DROP, SAMPLE_FILL_BLANKS, SAMPLE_MULTI_CHOICE, SAMPLE_SINGLE_CHOICE];
		let task = this.state.task;
		if (samples.some(s => s == this.state.task)) {
			switch(type) {
				case "MULTI_CHOICE": task = SAMPLE_MULTI_CHOICE; break;
				case "SINGLE_CHOICE": task = SAMPLE_SINGLE_CHOICE; break;
				case "DRAG_AND_DROP": task = SAMPLE_DRAG_AND_DROP; break;
				case "DROP_DOWN": task = SAMPLE_DROP_DOWN; break;
				case "FILL_BLANKS": task = SAMPLE_FILL_BLANKS; break;
			}
		}
		this.setState({ type, task });
		this.props.questionsEditorSetFormDirty();
	}

	handleDelete = (e) => {
		if (window.confirm('Are you sure?'))
			this.props.questionsCrudDelete(this.props.question.uuid);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const question = {...values, text: this.state.task};
				const prevChallenges = this.props.challenges.filter(c => c.questions.some(q => q.uuid == question.uuid));
				const currentChallenges = this.props.challenges.filter(c => values.challenges.some(c1 => c.uuid == c1));
				const removedFrom = prevChallenges.filter(c => !currentChallenges.some(c1 => c1.uuid == c.uuid));
				const addedTo = currentChallenges.filter(c => !prevChallenges.some(c1 => c1.uuid == c.uuid));
				if (this.props.question.uuid)
					this.props.questionsCrudUpdate(question, removedFrom, addedTo);
				else
					this.props.questionsCrudCreate(question, addedTo);
			}
		});
	}

	render() {
		const {
			getFieldDecorator
		} = this.props.form;

		/*
		const formItemLayout = {
			labelCol: { span: 4 },
			wrapperCol: { span: 20 },
		};
		*/

	    const childprops = {
	          question: new Question({...this.props.question, type: this.state.type, text: this.state.task}),
	          answer: this.state.answer,
	          onSetAnswer: this.onSetAnswer,
	          showValidationMessage: false,
	          mutating: false,
	        };

		return (
				<div>
				<Prompt
					when={this.props.isDirty}
					message={location => 'There are unsaved changes. Do you want to navigate away?'}
				/>
				<Form
					onSubmit={this.handleSubmit}
				>
					<div>
						<Button className="hor_btn" type="primary" disabled={!this.props.isDirty} htmlType="submit">
							Save
						</Button>
						{ this.props.question.uuid && (
							<Button className="hor_btn" type="danger" onClick={this.handleDelete}>Delete</Button>
						)}
					</div>
					{ this.props.serverError && (
						<Alert
    					  message="Server Error"
      					  description={this.props.serverError.message}
      					  type="error"
      					  showIcon
    					/>
					)}
					<Form.Item label="Slug">
						{getFieldDecorator('slug', {
						rules: [{ required: true, message: 'Slug should not be empty' }],
						initialValue: this.props.question.slug
						})(
							<Input onChange={this.handleValueChange} />
						)}
					</Form.Item>
					<Form.Item label="Type">
						{getFieldDecorator('type', {
						rules: [],
						initialValue: this.props.question.type
						})(
							<Select
								style={{ width: '32%' }}
								onChange={this.handleTypeChange}
							>
								<Option value="MULTI_CHOICE">Multi-choice</Option>
								<Option value="SINGLE_CHOICE">Single-choice</Option>
								<Option value="DROP_DOWN">Drop-down</Option>
								<Option value="DRAG_AND_DROP">Drag & drop</Option>
								<Option value="FILL_BLANKS">Fill the blanks</Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item label="Tags">
						{getFieldDecorator('tags', {
							rules: [
								{ required: true, message: 'Please choose question tags', type: 'array' },
							],
							initialValue: this.props.question.tags.map(t => t.uuid)
						})(
							<TreeSelect
								allowClear
						        style={{ width: 500 }}
						        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
						        treeData={chopTrees(this.props.tags, 'uuid')}
						        placeholder="Please select tags"
						        treeDefaultExpandAll
						        showSearch={true}
								multiple={true}
								onChange={this.handleValueChange}
						    />
						)}
					</Form.Item>
					<Form.Item label="Challenges">
						{getFieldDecorator('challenges', {
							rules: [
								{ required: false, message: 'Please choose challenges for the question', type: 'array' },
							],
							initialValue: this.props.challenges.filter(c => c.questions.some(q => q.uuid == this.props.question.uuid)).map(t => t.uuid)
						})(
							<Select
								allowClear
								style={{ width: 500 }}
								dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
								filterOption={true}
								placeholder="Assign question to challenges"
								onChange={this.handleValueChange}
								showSearch={true}
								mode="multiple"
							>
								{this.props.challenges.map(c => <Option key={c.uuid}>{`${c.slug} / ${c.title}`}</Option>)}
							</Select>
						)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('nonTest', {
							rules: [],
							valuePropName: 'checked',
							initialValue: this.props.question.nonTest
						})(
							<Checkbox onChange={this.handleValueChange}>Exclude from tests</Checkbox>
						)}
					</Form.Item>
					<h2>Question</h2>
					<ReactMde
						onChange={this.handleTaskChange}
						onTabChange={this.handleTaskTabChange}
						value={this.state.task}
						generateMarkdownPreview={markdown => {
							return new Promise(resolve => {
								let a = document.createElement('div');
								ReactDOM.render(<ReactMarkdown source={markdown} />, a, () => {
									resolve(a.innerHTML);
								});
							});
						}}
						selectedTab={this.state.tabTask}
					/>
					<Form.Item>
						{getFieldDecorator('uuid', {
						rules: [],
						initialValue: this.props.question.uuid
						})(
							<input type="hidden" />,
						)}
					</Form.Item>
				</Form>
				<Tabs defaultActiveKey="1">
					<TabPane tab="Question Preview" key="1">
						<QuestionDisplay {...childprops} />
					</TabPane>
					<TabPane tab="Answer Preview" key="2">
						<QuestionDisplay {...childprops} disabled={true} />
					</TabPane>
				</Tabs>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		tags: state.tags.data,
		challenges: state.challenges.data,
		isDirty: state.questionsEditor.dirty,
		serverError: state.errors.saveError,
		question: state.questionsEditor.question,
	};
}

export default connect(mapStateToProps,
						{ questionsEditorSetFormDirty,
							questionsCrudCreate,
							questionsCrudUpdate,
							questionsCrudDelete,
						})(Form.create()(QuestionFormComponent));
