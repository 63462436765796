import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from 'react-redux';
import { Alert, Form, Input, Button, Select, TreeSelect, Checkbox } from 'antd';

import { Prompt } from "react-router-dom";

import ReactMde from "react-mde";
import ReactMarkdown from 'react-markdown';

import "react-mde/lib/styles/css/react-mde-all.css";

import { challengesCrudCreate, challengesCrudUpdate, challengesCrudDelete, challengesEditorSetFormDirty } from '../../actions';
import { chopTrees } from '../../utils/tagsHelpers';
import { questionType2DisplayName } from '../../utils/editorHelpers';
import * as TestType from '../../constants/testType';

const { Option } = Select;

/* eslint react/forbid-prop-types: 0 */

class ChallengeFormComponent extends React.Component {

	constructor (props) {
		super(props);
		const description = props.challenge ? props.challenge.description : null;
		const shortDescription = props.challenge ? props.challenge.shortDescription : null;
		this.state = {
			description,
			shortDescription,
			tabTask: 'write',
		};
	}

	handleDescriptionChange = (description) => {
		this.setState({ description });
		this.props.challengesEditorSetFormDirty();
	};

	handleShortDescriptionChange = (shortDescription) => {
		this.setState({ shortDescription });
		this.props.challengesEditorSetFormDirty();
	};

	handleTaskTabChange = tabTask => {
		this.setState({ tabTask });
	};

	handleValueChange = () => {
		this.props.challengesEditorSetFormDirty();
	}

	handleDelete = (e) => {
		if (window.confirm('Are you sure?'))
			this.props.challengesCrudDelete(this.props.challenge.uuid);
	}

	handleTagsUpdate = (e) => {
		const usedQuestionUuids = this.props.challenge.questions.map(q => q.uuid);
		const usedQuestions = this.props.questions.filter(q => usedQuestionUuids.some(u => u == q.uuid));
		const tagsSet = new Set();
		usedQuestions.forEach(q => q.tags.map(t => t.uuid).forEach(tagsSet.add, tagsSet));
		this.props.form.setFieldsValue({tags: [...tagsSet]});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const challenge = {...values, description: this.state.description, shortDescription: this.state.shortDescription};
				if (this.props.challenge.uuid)
					this.props.challengesCrudUpdate(challenge);
				else
					this.props.challengesCrudCreate(challenge);
			}
		});
	}

	render() {
		const {
			getFieldDecorator
		} = this.props.form;

		/*
		const formItemLayout = {
			labelCol: { span: 4 },
			wrapperCol: { span: 20 },
		};
		*/

		return (
				<div>
				<Prompt
					when={this.props.isDirty}
					message={location => 'There are unsaved changes. Do you want to navigate away?'}
				/>
				<Form
					onSubmit={this.handleSubmit}
				>
					<h2>
						{this.props.challenge.title}
						<Button className="hor_btn" type="primary" disabled={!this.props.isDirty} htmlType="submit">
							Save
						</Button>
						{ this.props.challenge.uuid && (
							<Button className="hor_btn" type="danger" onClick={this.handleDelete}>Delete</Button>
						)}
					</h2>
					{ this.props.serverError && (
						<Alert
    					  message="Server Error"
      					  description={this.props.serverError.message}
      					  type="error"
      					  showIcon
    					/>
					)}
					<Form.Item
					label="Slug"
					>
						{getFieldDecorator('slug', {
						rules: [{ required: true, message: 'Slug should not be empty' }],
						initialValue: this.props.challenge.slug
						})(
							<Input onChange={this.handleValueChange} />
						)}
					</Form.Item>
					<Form.Item
					label="Title"
					>
						{getFieldDecorator('title', {
						rules: [{ required: true, message: 'Title should not be empty' }],
						initialValue: this.props.challenge.title
						})(
							<Input onChange={this.handleValueChange} />
						)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('draft', {
							rules: [],
							valuePropName: 'checked',
							initialValue: (this.props.challenge.draft !== undefined) ? this.props.challenge.draft : true
						})(
							<Checkbox onChange={this.handleValueChange}>Draft</Checkbox>
						)}
					</Form.Item>
					<h2>Short Description</h2>
					<ReactMde
						onChange={this.handleShortDescriptionChange}
						onTabChange={this.handleTaskTabChange}
						value={this.state.shortDescription}
						generateMarkdownPreview={markdown => {
							return new Promise(resolve => {
								let a = document.createElement('div');
								ReactDOM.render(<ReactMarkdown source={markdown} />, a, () => {
									resolve(a.innerHTML);
								});
							});
						}}
						selectedTab={this.state.tabTask}
					/>
					<h2>Description</h2>
					<ReactMde
						onChange={this.handleDescriptionChange}
						onTabChange={this.handleTaskTabChange}
						value={this.state.description}
						generateMarkdownPreview={markdown => {
							return new Promise(resolve => {
								let a = document.createElement('div');
								ReactDOM.render(<ReactMarkdown source={markdown} />, a, () => {
									resolve(a.innerHTML);
								});
							});
						}}
						selectedTab={this.state.tabTask}
					/>
					<Form.Item>
						{getFieldDecorator('uuid', {
						rules: [],
						initialValue: this.props.challenge.uuid
						})(
							<input type="hidden" />,
						)}
					</Form.Item>
					<Form.Item label="Tags">
						{getFieldDecorator('tags', {
							rules: [
								{ required: false, message: 'Please choose challenge tags', type: 'array' },
							],
							initialValue: this.props.challenge.tags.map(t => t.uuid)
						})(
							<TreeSelect
								allowClear
						        style={{ width: 500 }}
						        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
						        treeData={chopTrees(this.props.tags, 'uuid')}
						        placeholder="Please select tags"
						        treeDefaultExpandAll
						        showSearch={true}
								multiple={true}
								onChange={this.handleValueChange}
						    />
						)}
					</Form.Item>
					<Button className="hor_btn" type="primary" onClick={this.handleTagsUpdate}>Update Tags from Questions</Button>
					<Form.Item label="Questions">
						{getFieldDecorator('questions', {
							rules: [
								{ required: false, message: 'Please choose challenge questions', type: 'array' },
							],
							initialValue: this.props.challenge.questions.map(t => t.uuid)
						})(
							<Select
								allowClear
								style={{ width: 500 }}
								dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
								filterOption={true}
								placeholder="Add questions to this challenge"
								onChange={this.handleValueChange}
								showSearch={true}
								mode="multiple"
							>
								{this.props.questions.map(q => <Option key={q.uuid}>{`${q.slug} / ${questionType2DisplayName(q.type)}`}</Option>)}
							</Select>
						)}
					</Form.Item>
					<Form.Item label="Banners">
						{getFieldDecorator('banners', {
							rules: [
							],
							initialValue: this.props.challenge.banners.map(t => t.uuid)
						})(
							<Select
								allowClear
								style={{ width: 500 }}
								dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
								filterOption={true}
								placeholder="Add banners to this challenge"
								onChange={this.handleValueChange}
								showSearch={true}
								mode="multiple"
							>
								{this.props.banners.map(q => <Option key={q.uuid}>{q.label}</Option>)}
							</Select>
						)}
					</Form.Item>
					<Form.Item label="Test Type">
						{getFieldDecorator('testType', {
							rules: [
							],
							initialValue: this.props.challenge.testType
						})(
							<Select
								allowClear
								style={{ width: 500 }}
								dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
								// filterOption={true}
								placeholder="Test type. Challenge is the default."
								onChange={this.handleValueChange}
								// showSearch={true}
								// mode="multiple"
							>
								<Option value={TestType.CHALLENGE} key={TestType.CHALLENGE}>{TestType.CHALLENGE}</Option>
								<Option value={TestType.QUIZ} key={'QUIZ'}>{'QUIZ'}</Option>
								<Option value={'CEFR_TEST'} key={'CEFR_TEST'}>{'CEFR_TEST'}</Option>
							</Select>
						)}
					</Form.Item>
				</Form>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		tags: state.tags.data,
		questions: state.questions.data,
		banners: state.banners.data,
		isDirty: state.challengesEditor.dirty,
		serverError: state.errors.saveError,
		challenge: state.challengesEditor.challenge,
	};
}

export default connect(mapStateToProps,
						{ challengesEditorSetFormDirty,
							challengesCrudCreate,
							challengesCrudUpdate,
							challengesCrudDelete,
						})(Form.create()(ChallengeFormComponent));
