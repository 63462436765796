export function combineCUD(objects, created, updated, deleted) {
  let result = objects.filter(object => !deleted[object.uuid]);
  result = result.map(object => updated[object.uuid] || object);
  return [...result, ...Object.values(created)];
}

export function questionType2DisplayName(type) {
	switch(type){
		case 'SINGLE_CHOICE': return 'Single-choice';
		case 'MULTI_CHOICE': return 'Multi-choice';
		case 'DROP_DOWN': return 'Drop-down';
		case 'DRAG_AND_DROP': return 'Drag & drop';
		case 'FILL_BLANKS': return 'Fill the blanks';
		default:
			return type;
	}
}

export function getTagsBySlugs(slugs, tags) {
	return slugs.map(slug => tags.filter(tag => tag.slug === slug)[0]);
}

export function isEmpty(obj) {
	return Object.entries(obj).length === 0 && obj.constructor === Object;
}
