import gql from 'graphql-tag';

import client from './apolloClient';

async function loadTags() {
	return client.query({
		query: gql`{
			tags {
				uuid
				slug
				title
				parentSlug
			}
		}`
	});
}

async function loadTagBySlug(slug) {
	return client.query({
		variables: { slug },
			query: gql`
			query ($slug: String!) {
				tagBySlug(slug: $slug) {
					uuid
					slug
					title
					shortDescription
					description
					license
					descriptionUrls
					parent {
						slug
					}
				}
			}`
	});
}

const toGQLtag = ({uuid, slug, title, shortDescription, description, license, descriptionUrls, parentSlug}) => {
	return {
		uuid,
		slug,
		title,
		shortDescription,
		description,
		license,
		descriptionUrls,
		parentSlug,
	};
};

async function updateTag(tag) {
	return client.mutate({
			variables: { tag: toGQLtag(tag) },
			mutation: gql`
				mutation ($tag: TagInput!){
						updateTag(tag: $tag) {
							uuid
			 				slug
					title
					shortDescription
					description
					parentSlug
					license
					descriptionUrls
						}
				}
			`,
	});
}

async function createTag(tag) {
	return client.mutate({
			variables: { tag: toGQLtag(tag) },
			mutation: gql`
				mutation ($tag: TagInput!){
						createTag(tag: $tag) {
							uuid
			 				slug
					title
					shortDescription
					description
					parentSlug
					license
					descriptionUrls
						}
				}
			`,
	});
}

async function deleteTag(tagUuid) {
	return client.mutate({
			variables: { tagUuid },
			mutation: gql`
				mutation ($tagUuid: ID!){
						deleteTag(tagUuid: $tagUuid)
				}
			`,
	});
}

async function loadQuestions() {
	return client.query({
		query: gql`{
			questionsByAuthor {
				uuid
				slug
				type
						tags {
							uuid
							slug
						}
						nonTest
			}
		}`
	});
}

async function loadQuestionBySlug(slug) {
	return client.query({
		variables: { slug },
			query: gql`
			query ($slug: String!) {
				questionByQuestionSlug(slug: $slug) {
					uuid
					slug
					text
					type
							tags {
								uuid
								slug
							}
							nonTest
				}
			}`
	});
}

const toGQLQuestion = ({slug, type, tags, uuid, text, nonTest}) => {
	return {
		slug,
		type,
		tagsLink: tags.map(tagUuid => { return { tagUuid } }),
		uuid,
		text,
		nonTest
	};
}

async function updateQuestion(question) {
	return client.mutate({
			variables: { question: toGQLQuestion(question) },
			mutation: gql`
				mutation ($question: QuestionInput!){
						updateQuestion(question: $question) {
							uuid
					slug
					text
					type
							tags {
								uuid
								slug
							}
							nonTest
						}
					
				}
			`,
	});
}

async function createQuestion(question) {
	return client.mutate({
			variables: { question: toGQLQuestion(question) },
			mutation: gql`
				mutation ($question: QuestionInput!){
						createQuestion(question: $question) {
							uuid
					slug
					text
					type
							tags {
								uuid
								slug
							}
							nonTest
					}
					
				}
			`,
	});
}

async function deleteQuestion(questionUuid) {
	return client.mutate({
			variables: { questionUuid },
			mutation: gql`
				mutation ($questionUuid: ID!){
						deleteQuestion(questionUuid: $questionUuid)
				}
			`,
	});
}

async function loadChallenges() {
	return client.query({
		query: gql`{
			challengesByAuthor {
				uuid
				slug
				title
				draft
				description
				questions {
					uuid
					slug
				}
				tags {
					uuid
							slug
						}
						banners {
					uuid
					label
				}
			}
		}`
	});
}

async function loadChallengeBySlug(slug) {
	return client.query({
		variables: { slug },
			query: gql`
			query ($slug: String!) {
				challengeBySlug(slug: $slug) {
					uuid
					slug
					title
					draft
					shortDescription
					description
					questions {
						uuid
						slug
					}
					tags {
						uuid
								slug
							}
							banners {
						uuid
						label
					}
					testType
				}
			}`
	});
}

const toGQLChallenge = ({slug, title, draft, tags, questions, banners, uuid, description, shortDescription, testType}) => {
	return {
		slug,
		title,
		draft,
		tagsLink: tags.map(tagUuid => { return { tagUuid } }),
		questionsLink: questions.map((questionUuid, order) => { return { questionUuid, order:order+1 } }), //order started from 1
		bannersLink: banners.map(userBannerUuid => { return { userBannerUuid } }),
		uuid,
		shortDescription,
		description,
		testType,
	};
}

async function updateChallenge(challenge) {
	return client.mutate({
			variables: { challenge: toGQLChallenge(challenge) },
			mutation: gql`
				mutation ($challenge: ChallengeInput!){
					updateChallenge(challenge: $challenge) {
						uuid
						slug
						title
						draft
						shortDescription
						description
						questions {
							uuid
							slug
						}
						tags {
							uuid
							slug
						}
						banners {
							uuid
							label
						}
						testType
					}
				}
			`,
	});
}

async function createChallenge(challenge) {
	return client.mutate({
			variables: { challenge: toGQLChallenge(challenge) },
			mutation: gql`
				mutation ($challenge: ChallengeInput!){
					createChallenge(challenge: $challenge) {
						uuid
						slug
						title
						draft
						shortDescription
						description
						questions {
							uuid
							slug
						}
						tags {
							uuid
							slug
						}
						banners {
							uuid
							label
						}
						testType
					}
				}
			`,
	});
}

async function deleteChallenge(challengeUuid) {
	return client.mutate({
			variables: { challengeUuid },
			mutation: gql`
				mutation ($challengeUuid: ID!){
					deleteChallenge(challengeUuid: $challengeUuid)
				}
			`,
	});
}

async function loadBanners() {
	return client.query({
		query: gql`{
			bannersByAuthor {
				uuid
				label
			}
		}`
	});
}

async function loadBannerById(uuid) {
	return client.query({
		variables: { uuid },
			query: gql`
			query ($uuid: ID!) {
				bannerById(uuid: $uuid) {
					uuid
					label
					bannerTarget
					bannerPath
					type
				}
			}`
	});
}

const toGQLBanner = ({uuid, label, bannerTarget, bannerData, type}) => {
	return {
		uuid,
		label,
		bannerTarget,
		bannerData,
		type,
	};
}

async function updateBanner(banner) {
	return client.mutate({
			variables: { banner: toGQLBanner(banner) },
			mutation: gql`
				mutation ($banner: UserBannerInput!){
						updateBanner(banner: $banner) {
							uuid
					label
					bannerTarget
					bannerPath
					type
						}
				}
			`,
	});
}

async function createBanner(banner) {
	return client.mutate({
			variables: { banner: toGQLBanner(banner) },
			mutation: gql`
				mutation ($banner: UserBannerInput!){
						createBanner(banner: $banner) {
							uuid
					label
					bannerTarget
					bannerPath
					type
						}
				}
			`,
	});
}

async function deleteBanner(bannerUuid) {
	return client.mutate({
			variables: { bannerUuid },
			mutation: gql`
				mutation ($bannerUuid: ID!){
						deleteBanner(bannerUuid: $bannerUuid)
				}
			`,
	});
}

export {
	loadTags,
	loadTagBySlug,
	updateTag,
	createTag,
	deleteTag,
	loadQuestions,
	loadQuestionBySlug,
	updateQuestion,
	createQuestion,
	deleteQuestion,
	loadChallenges,
	loadChallengeBySlug,
	updateChallenge,
	createChallenge,
	deleteChallenge,
	loadBanners,
	loadBannerById,
	updateBanner,
	createBanner,
	deleteBanner,
};
